import $ from 'jquery';

(function (window) {
  'use strict';
  var document = window.document,
    Image = window.Image,
    localStore = window.localStorage,
    sessionStorage = window.sessionStorage;

  function _ec_replace(str, key, value) {
    if (str.indexOf("&" + key + "=") > -1 || str.indexOf(key + "=") === 0) {
      // find start
      var idx = str.indexOf("&" + key + "="),
        end, newstr;
      if (idx === -1) {
        idx = str.indexOf(key + "=");
      }
      // find end
      end = str.indexOf("&", idx + 1);
      if (end !== -1) {
        newstr = str.substr(0, idx) + str.substr(end + (idx ? 0 : 1)) + "&" + key + "=" + value;
      } else {
        newstr = str.substr(0, idx) + "&" + key + "=" + value;
      }
      return newstr;
    } else {
      return str + "&" + key + "=" + value;
    }
  }

  var defaultOptionMap = {
    domain: '.' + window.location.host.replace(/:\d+/, ''), // Get current domain
    baseurl: '/rendition/eppublic', // base url for php, flash and silverlight assets
    evercookieuri: '/evercookie', // php file path or route
    pngCookieName: 'evercookie_png',
    pngPath: '/pngcache',
    etagCookieName: 'evercookie_etag',
    etagPath: '/etagcache',
    cacheCookieName: 'evercookie_cache',
    cachePath: '/requestcache',
    cookieExpiry: 365
  };
  
  /**
   * @class Evercookie
   * @param {Object} options
   * @param {String} options.domain (eg: www.sitename.com use .sitename.com)
   * @param {String} options.baseurl base url (eg: www.sitename.com/demo use /demo)
   * @param {String} options.evercookieuri php path/route (eg: www.sitename.com/php use /php)
   * @param {String|Function} options.domain as a string, domain for cookie, as a function, accept window object and return domain string
   * @param {String} options.pngCookieName
   * @param {String} options.pngPath
   * @param {String} options.etagCookieName:
   * @param {String} options.etagPath
   * @param {String} options.cacheCookieName
   * @param {String} options.cachePath
   */
  function Evercookie(options) {
    options = options || {};
    var opts = {};
    for (var key in defaultOptionMap) {
      var optValue = options[key];
      if(typeof optValue !== 'undefined') {
        opts[key] = optValue
      } else {
        opts[key] = defaultOptionMap[key];
      }
    }
    var _ec_baseurl = opts.baseurl,
      _ec_evercookieuri = opts.evercookieuri,
      _ec_domain = opts.domain;

    // private property
    var self = this;
    this._ec = {};

    var getReturnObj = function(type, val) {
    	return {
    		type : type,
    		val : val
    	}
    }
    this.getFromCookie = function(cname) {
    	console.log("Trying to get from COOKIE_DATA");
    	return getReturnObj('COOKIE_DATA', self.evercookie_cookie(cname));
    };
    this.getFromWindowName = function(cname) {
    	console.log("Trying to get from WINDOW_NAME_DATA");
    	return getReturnObj('WINDOW_NAME_DATA', self.evercookie_window(cname));
    };
    this.getFromUserData = function(cname) {
    	console.log("Trying to get from USER_DATA");
    	return getReturnObj('USER_DATA', self.evercookie_userdata(cname));
    };
    this.getFromLocalStorage = function(cname) {
    	console.log("Trying to get from LOCAL_STORAGE_DATA");
    	return getReturnObj('LOCAL_STORAGE_DATA', self.evercookie_local_storage(cname));
    };
    this.getFromSessionStorage = function(cname) {
    	console.log("Trying to get from SESSION_STORAGE_DATA");
    	return getReturnObj('SESSION_STORAGE_DATA', self.evercookie_session_storage(cname));
    };
    this.getFromIndexedDB = function(cname) {
    	var xPromise = self.evercookie_indexdb_storage(cname);
    	console.log("Trying to get from INDEXED_DB");
    	xPromise.done(function(x) {
    		console.log("Trying to get from INDEXED_DB (SUCCESS): ", x);
    	}).fail(function(errMsg) {
    		console.log("Trying to get from INDEXED_DB (FAILURE): ", errMsg);
    	});
    	return xPromise;
    };
    this.getFromWebSQLDB = function(cname) {
    	var xPromise = self.evercookie_database_storage(cname);
    	console.log("Trying to get from WEBSQL_DB");
    	xPromise.done(function(x) {
    		console.log("Trying to get from WEBSQL_DB (SUCCESS): ", x);
    	}).fail(function(errMsg) {
    		console.log("Trying to get from WEBSQL_DB (FAILURE): ", errMsg);
    	});
    	return xPromise;
    };
    this.getFromRequestCache = function(cname) {
    	var xPromise = self.evercookie_cache(cname);
    	console.log("Trying to get from REQUEST_CACHE");
    	xPromise.done(function(x) {
    		console.log("Trying to get from REQUEST_CACHE (SUCCESS): ", x);
    	}).fail(function(errMsg) {
    		console.log("Trying to get from REQUEST_CACHE (FAILURE): ", errMsg);
    	});
    	return xPromise;
    };
    /*this.getFromPngCache = function(cname) {
    	var xPromise = self.evercookie_png(cname);
    	console.log("Trying to get from PNG_CACHE");
    	xPromise.done(function(x) {
    		console.log("Trying to get from PNG_CACHE (SUCCESS): ", x);
    	}).fail(function(errMsg) {
    		console.log("Trying to get from PNG_CACHE (FAILURE): ", errMsg);
    	});
    	return xPromise;
    };*/
    this.getFromEtagCache = function(cname) {
    	var xPromise = self.evercookie_etag(cname);
    	console.log("Trying to get from ETAG_CACHE");
    	xPromise.done(function(x) {
    		console.log("Trying to get from ETAG_CACHE (SUCCESS): ", x);
    	}).fail(function(errMsg) {
    		console.log("Trying to get from ETAG_CACHE (FAILURE): ", errMsg);
    	});
    	return xPromise;
    };
    
    this.get = function (cname) {
    	function executeFailureBlock() {
    		if(indexedDBPromiseFailed 
    				&& webSqlDBPromiseFailed
    				&& requestCachePromiseFailed
    				/*&& pngCachePromiseFailed*/
    				&& etagCachePromiseFailed) {
    			defer.reject("Unable to get data");
    		}
    	}
    	var x, 
    		defer = $.Deferred(), 
    		indexedDBPromiseFailed = false, 
    		webSqlDBPromiseFailed = false,
    		requestCachePromiseFailed = false,
    		/*pngCachePromiseFailed = false,*/
    		etagCachePromiseFailed = false;
    	const fnList = [this.getFromWindowName, this.getFromUserData, this.getFromLocalStorage, this.getFromSessionStorage];
    	for(let i = 0; i < fnList.length; i++) {
    	   const fn = fnList[i];
    	   x = fn(cname);
    	   if(x.val) {
    	     defer.resolve(x);
    	     break;
    	   }
    	}
    	if(defer.state()!=='resolved') {
	    	this.getFromIndexedDB(cname).done(function(x) {
	    		defer.resolve(x);
	    	}).fail(function() {
	    		indexedDBPromiseFailed = true;
	    		executeFailureBlock();
	    	});
	    	this.getFromWebSQLDB(cname).done(function(x) {
	    		defer.resolve(x);
	    	}).fail(function() {
	    		webSqlDBPromiseFailed = true;
	    		executeFailureBlock();
	    	});
	    	this.getFromRequestCache(cname).done(function(x) {
	    		defer.resolve(x);
	    	}).fail(function() {
	    		requestCachePromiseFailed = true;
	    		executeFailureBlock();
	    	});
	    	/*this.getFromPngCache(cname).done(function(x) {
	    		defer.resolve(x);
	    	}).fail(function() {
	    		pngCachePromiseFailed = true;
	    		executeFailureBlock();
	    	});*/
	    	this.getFromEtagCache(cname).done(function(x) {
	    		defer.resolve(x);
	    	}).fail(function() {
	    		etagCachePromiseFailed = true;
	    		executeFailureBlock();
	    	});
    	}
    	return defer.promise();
    };

    this.set = function (name, value) {
        //self.evercookie_png(name, value);
        self.evercookie_etag(name, value);
        self.evercookie_cache(name, value);
        self.evercookie_userdata(name, value);
        self.evercookie_local_storage(name, value);
        self.evercookie_session_storage(name, value);
        self.evercookie_window(name, value);
        self.evercookie_indexdb_storage(name, value);
        self.evercookie_database_storage(name, value);
    };
    
    this.deleteCookie = function(cname) {
    	document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=" + _ec_domain;
    }
    
    this.dropCookie = function(cname, cvalue) {
    	// First delete cookie   	
    	this.deleteCookie();
    	
    	// Drop new cookie
    	var d = new Date(); 
        d.setTime(d.getTime() + (opts.cookieExpiry*24*60*60*1000));
        document.cookie = cname + "=" + cvalue + "; expires=" + d.toUTCString() + "; path=/; domain=" + _ec_domain;
    };

    this.evercookie_window = function (name, value) {
      try {
        if (value !== undefined) {
          window.name = _ec_replace(window.name, name, value);
        } else {
          return this.getFromStr(name, window.name);
        }
      } catch (e) { }
    };

    this.evercookie_userdata = function (name, value) {
      try {
        var elm = this.createElem("div", "userdata_el", 1);
        if (elm.addBehavior) {
          elm.style.behavior = "url(#default#userData)";

          if (value !== undefined) {
            elm.setAttribute(name, value);
            elm.save(name);
          } else {
            elm.load(name);
            return elm.getAttribute(name);
          }
        }
      } catch (e) {}
    };

    this.evercookie_local_storage = function (name, value) {
      try {
        if (localStore) {
          if (value !== undefined) {
            localStore.setItem(name, value);
          } else {
            return localStore.getItem(name);
          }
        }
      } catch (e) { }
    };

    this.evercookie_session_storage = function (name, value) {
      try {
        if (sessionStorage) {
          if (value !== undefined) {
            sessionStorage.setItem(name, value);
          } else {
            return sessionStorage.getItem(name);
          }
        }
      } catch (e) { }
    };
    
    this.evercookie_database_storage = function (name, value) {
      var defer = $.Deferred();
      try {
        if (window.openDatabase) {
          var database = window.openDatabase("sqlite_evercookie", "", "evercookie", 10 * 1024);
          if (value !== undefined) {
            database.transaction(function (tx) {
              tx.executeSql("CREATE TABLE IF NOT EXISTS cache(" +
                "id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT, " +
                "name TEXT NOT NULL, " +
                "value TEXT NOT NULL, " +
                "UNIQUE (name)" +
                ")", [], function (tx, rs) {}, function (tx, err) {});
              tx.executeSql("INSERT OR REPLACE INTO cache(name, value) " +
                "VALUES(?, ?)",
                [name, value], function (tx, rs) {}, function (tx, err) {});
              defer.resolve("Successfully stored in webSQL db");
            });
          } else {
            database.transaction(function (tx) {
              tx.executeSql("SELECT value FROM cache WHERE name=?", [name],
                function (tx, result1) {
                  if (result1.rows.length >= 1) {
                    defer.resolve(getReturnObj('WEBSQL_DB_DATA', result1.rows.item(0).value));
                  } else {
                    defer.reject("No results from webSQL");
                  }
                }, function (tx, err) {
                	defer.reject("Error executing query in webSQL");
                });
            });
          }
        }
        else {
	    	defer.reject("No window.openDatabase");
	    }
      } catch (e) {
    	  defer.reject("Exception while storing in WebSQL");
      }
      return defer.promise();
    };
 
    this.evercookie_indexdb_storage = function(name, value) {
     var defer = $.Deferred();
     try {
	    if (!('indexedDB' in window)) {
	        indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
	        IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
	        IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
	    }
	    if (indexedDB) {
	        var ver = 1;
	        //FF incognito mode restricts indexedb access
	        var request = indexedDB.open("idb_evercookie", ver);
	
	        request.onerror = function(e) {
	        	defer.reject("Error opening db");
	        }
	
	        request.onupgradeneeded = function(event) {
	            var db = event.target.result;
	            var store = db.createObjectStore("evercookie", {
	                keyPath: "name",
	                unique: false
	            })
	        }
	
	        if (value !== undefined) {
	            request.onsuccess = function(event) {
	                var idb = event.target.result;
	                if (idb.objectStoreNames.contains("evercookie")) {
	                    var tx = idb.transaction(["evercookie"], "readwrite");
	                    var objst = tx.objectStore("evercookie");
	                    var qr = objst.put({
	                        "name": name,
	                        "value": value
	                    })
	                } 
	                idb.close();
	                defer.resolve("Successfully stored in Indexed db");
	            }
	        } 
	        else {
	            request.onsuccess = function(event) {
	                var idb = event.target.result;
	                if (!idb.objectStoreNames.contains("evercookie")) {
	                    defer.reject("evercookie object store not found");
	                } else {
	                    var tx = idb.transaction(["evercookie"]);
	                    var objst = tx.objectStore("evercookie");
	                    var qr = objst.get(name);
	                    qr.onsuccess = function(event) {
	                        if (qr.result === undefined) {
	                            defer.reject("Query result is undefined");
	                        } else {
	                        	defer.resolve(getReturnObj('INDEXED_DB_DATA', qr.result.value));
	                        }
	                    }
	                    qr.onerror = function(event) {
	                    	defer.reject("Error in Query execution");
	                    }
	                }
	                idb.close();
	            }
	        }
	    }
	    else {
	    	defer.reject("No indexedDB");
	    }
	 } catch (e) {
		 defer.reject("Exception while storing in IndexedDB");
	 }
	 return defer.promise();
	};

    this.evercookie_cache = function (name, value) {
      if (value !== undefined) {
    	this._setcookie(opts.cacheCookieName, value, 15);  
        $.ajax({
          url: _ec_baseurl + _ec_evercookieuri + opts.cachePath,
          type: 'GET',
          dataType:'json',
          data: {
        	 name : name,
        	 cookie : opts.cacheCookieName
          }
        });
      } 
      else {
    	var deferred = $.Deferred();
    	try {
    		var origvalue = this.getFromStr(opts.cacheCookieName, document.cookie);
            if(origvalue) {
            	deferred.resolve(getReturnObj('REQUEST_CACHE',origvalue));
            }
            else {
            	this.expireCookie(opts.cacheCookieName);
                var ajaxPromise = $.ajax({
        	        url: _ec_baseurl + _ec_evercookieuri + opts.cachePath,
        	        type: 'GET',
        	        dataType:'json',
        	        data: {
        	      	 name : name,
        	      	 cookie : opts.cacheCookieName
        	        }
        	      });
                ajaxPromise
        	        .done($.proxy(function(data) {
        	        	if(data) {
        	        		this._setcookie(opts.cacheCookieName, data, 15);  
            	            deferred.resolve(getReturnObj('REQUEST_CACHE',data));
        	        	}
        	        	else {
        	        		deferred.reject("Data undefined in request cache");
        	        	}
        	        }, this))
        	        .fail(function() {
        	        	deferred.reject("Ajax request failed while reading value from request cache");
        	        });
            }
    	}
    	catch (e) {
    		deferred.reject("Caught exception while reading evercookie from request cache");
    	}
        return deferred;        
      }
    };
    
    this.evercookie_etag = function (name, value) {
      if (value !== undefined) {
        this._setcookie(opts.etagCookieName, value, 15);  
        $.ajax({
          url: _ec_baseurl + _ec_evercookieuri + opts.etagPath,
          type: 'GET',
          dataType:'json',
          data: {
        	 name : name,
        	 cookie : opts.etagCookieName
          }
        });
      } 
      else {
        var deferred = $.Deferred();
    	try {
    		var origvalue = this.getFromStr(opts.etagCookieName, document.cookie);
            if(origvalue) {
            	deferred.resolve(getReturnObj('ETAG_CACHE',origvalue));
            }
            else {
            	this.expireCookie(opts.etagCookieName);
                var ajaxPromise = $.ajax({
        	        url: _ec_baseurl + _ec_evercookieuri + opts.etagPath,
        	        type: 'GET',
        	        dataType:'json',
        	        data: {
        	      	 name : name,
        	      	 cookie : opts.etagCookieName
        	        }
        	      });
                ajaxPromise
        	        .done($.proxy(function(data) {
        	        	if(data) {
	        	            this._setcookie(opts.etagCookieName, data, 15);  
	        	            deferred.resolve(getReturnObj('ETAG_CACHE',data));
        	        	}
        	            else {
        	        		deferred.reject("Data undefined in request cache");
        	        	}
        	        }, this))
        	        .fail(function() {
        	        	deferred.reject("Ajax request failed while reading value from Etag cache");
        	        });
            }
    	}
    	catch (e) {
    		deferred.reject("Caught exception while reading evercookie from etag");
    	}
        return deferred;  
      }
    };

    this.evercookie_png = function (name, value) {
      var canvas = document.createElement("canvas"),
       img, ctx, origvalue;
      canvas.style.visibility = "hidden";
      canvas.style.position = "absolute";
      canvas.width = 200;
      canvas.height = 1;
      var deferred = $.Deferred();
      if (canvas && canvas.getContext) {
        img = new Image();
        img.style.visibility = "hidden";
        img.style.position = "absolute";
        if (value !== undefined) {
          this._setcookie(opts.pngCookieName, value, 15);  
          img.src = _ec_baseurl + _ec_evercookieuri + opts.pngPath + "?name=" + name + "&cookie=" + opts.pngCookieName;
        } 
        else {
        	try {
        		var origvalue = this.getFromStr(opts.pngCookieName, document.cookie);
                if(origvalue) {
                	deferred.resolve(getReturnObj('PNG_CACHE',origvalue));
                }
                else {
                	this.expireCookie(opts.pngCookieName);
                	img.onload = function () {
                        
                        var pngData = "";
                        ctx.drawImage(img, 0, 0);

                        // get CanvasPixelArray from  given coordinates and dimensions
                        var imgd = ctx.getImageData(0, 0, 200, 1),
                          pix = imgd.data, i, n;

                        // loop over each pixel to get the "RGB" values (ignore alpha)
                        for (i = 0, n = pix.length; i < n; i += 4) {
                          if (pix[i] === 0) {
                            break;
                          }
                          pngData += String.fromCharCode(pix[i]);
                          if (pix[i + 1] === 0) {
                            break;
                          }
                          pngData += String.fromCharCode(pix[i + 1]);
                          if (pix[i + 2] === 0) {
                            break;
                          }
                          pngData += String.fromCharCode(pix[i + 2]);
                        }
                        
                        this._setcookie(opts.pngCookieName, pngData, 15);
                        deferred.resolve(getReturnObj('PNG_CACHE',pngData));
                        
                      };
                   img.onerror = function() {
                	   deferred.reject("Error ocurred while loading image (evercookie)");
                   }
                }
        	}
        	catch (e) {
        		deferred.reject("Caught exception while reading evercookie from image");
        	}
        	img.src = _ec_baseurl + _ec_evercookieuri + opts.pngPath + "?name=" + name + "&cookie=" + opts.pngCookieName;
        }
      }
      else {
    	  deferred.reject("Error while reading canvas object");
      }
      return deferred; 
    };

    this.createElem = function (type, name, append) {
      var el;
      if (name !== undefined && document.getElementById(name)) {
        el = document.getElementById(name);
      } else {
        el = document.createElement(type);
      }
      el.style.visibility = "hidden";
      el.style.position = "absolute";

      if (name) {
        el.setAttribute("id", name);
      }

      if (append) {
        document.body.appendChild(el);
      }
      return el;
    };

    this.evercookie_cookie = function (name, value) {
      if (value !== undefined) {
        // expire the cookie first
        document.cookie = name + "=; expires=Mon, 20 Sep 2010 00:00:00 UTC; path=/; domain=" + _ec_domain;
        document.cookie = name + "=" + value + "; expires=Tue, 31 Dec 2030 00:00:00 UTC; path=/; domain=" + _ec_domain;
      } else {
        return this.getFromStr(name, document.cookie);
      }
    };

    // get value from param-like string (eg, "x=y&name=VALUE")
    this.getFromStr = function (name, text) {
      if (typeof text !== "string") {
        return;
      }
      var nameEQ = name + "=",
        ca = text.split(/[;&]/),
        i, c;
      for (i = 0; i < ca.length; i++) {
        c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    };
    
    this.expireCookie = function (cname) {
    	document.cookie = cname + "=; expires=Mon, 20 Sep 2010 00:00:00 UTC; path=/;";
    };
    
    this._setcookie = function (cname, cvalue, expireYears) {
    	var expires = "";
    	if(expireYears) {
    		var d = new Date();
            d.setTime(d.getTime() + (expireYears*365*24*60*60*1000));
            expires = "expires="+d.toUTCString()+";";
        }
        document.cookie = cname + "=" + cvalue + "; path=/; " + expires;
    };
  }

  /**
   * Because Evercookie is a class, it should has first letter in capital
   * Keep first letter in small for legacy purpose
   * @expose Evercookie
   */
  window.evercookie = window.Evercookie = Evercookie;
}(window));