import React from 'react';
import _ from 'lodash';
import RegistrationContext from '../store/registrationContext';
import LanguageHeader from './languageHeader';
import { initialExecute, ncrReset } from '../services/ENT_script_grid_New';
import { getUrlParameter } from '../utils/registrationUtils';
import { getNewClientRegistrationDetail } from '../services/axios-services';
import Loading from '../utils/loading';
import history from '../history';

interface INewClientRegistrationStatus {
    locale: string;
    newClientRegistrationId: string;
    emailAddress: string;
    originURL: string;
    preConditionBln: boolean;
    disabledSubmitBtn: boolean;
}

class NewClientRegistration extends React.Component<any, INewClientRegistrationStatus> {

    public static contextType = RegistrationContext;

    constructor(props: any) {
        super(props);

        let localeParam = getUrlParameter('?', 'locale', '#/newClientRegistration');
        const newClientRegistrationId = getUrlParameter('?', 'newClientRegistrationId', '#/newClientRegistration' );
        const originURL = getUrlParameter('?', 'originURL', '#/newClientRegistration');
        if (_.isEmpty(newClientRegistrationId)) {
            console.error("No newClientRegistrationId for this new client registratoin:");
        }
        if (_.isEmpty(originURL)) {
            console.error("No originURL for this new client registratoin:");
        }
        if (_.isEmpty(localeParam)) {
            localeParam = 'EN_US';
        }

        this.state = {
                locale: localeParam,
                newClientRegistrationId,
                emailAddress: '',
                originURL,
                preConditionBln: true,
                disabledSubmitBtn: false
            };

    }

    public retrieveCompanyEmailAddress = (newClientRegistrationId: string) => {
        (async () => {
            const newClientRegistrationDetail = await getNewClientRegistrationDetail(newClientRegistrationId);
            if (newClientRegistrationDetail != null && !_.isEmpty(newClientRegistrationDetail.emailAddress)) {
                this.setState({
                    emailAddress: newClientRegistrationDetail.emailAddress
                });
            } else {
                this.setState({
                    preConditionBln: false
                });
                console.error("No emailAddress for this new client:", newClientRegistrationId);
                history.push({
                    pathname: '/newClientRegistrationError'
                });

            }
        })();
    };

    public componentDidMount() {
       this.context.onLocaleChange(this.state.locale);

       this.retrieveCompanyEmailAddress(this.state.newClientRegistrationId);

       initialExecute();
    }

    public render() {

        const { NewClientRegistrationText } = this.context.localeData;
        const locale = this.context.locale ?  this.context.locale : this.state.locale;

        return (
            <div id="outerContainer-reg">

                <LanguageHeader headerTitle={NewClientRegistrationText.NewClientRegistration.NEW_CLIENT_REGISTRATION_FORM} headerTitleClassName="titleTextStyle"/>

                <input type="hidden" id="newClientRegistrationId" value={this.state.newClientRegistrationId || ''} /> {/* <%=request.getHeader(" x-citiportal-LoginID")%> */}
                <input type="hidden" id="ncr_locale" value={locale || 'EN_US'} />

                <label style={{ paddingLeft: '20px', color: '#e60000' }}>*</label>
                <label>{NewClientRegistrationText.NewClientRegistration.Required}</label>
                <br />
                <div id="error" style={{ marginBottom: '10px', paddingLeft: '10px' }}>&nbsp;</div>
                <div id="ncr_content">
                    <div className="sectionTextStyle">{NewClientRegistrationText.NewClientRegistration.PERSONAL_INFORMATION}</div>
                    <div style={{ textAlign: 'left', paddingTop: '10px' }}>
                        <div className="separatortop">
                            <span > <label className="separatortopncr">{NewClientRegistrationText.NewClientRegistration.Position}</label></span>
                            <span className="separatortopncr">
                                <select id="ncr_position" name="Position" className="dropBox" title=""
                                    style={{ height: '20px', width: '300px', border: '1px solid #797979', fontSize: '11px!important', fontFamily: 'arial, sans-serif !important' }}>
                                    <option value='NONE'></option>
                                    {NewClientRegistrationText.PositionOptions.map((option: any, index: number) => (<option value={option.value} key={index}>{option.label}</option>))}
                                </select>
                            </span>
                        </div>
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.Client_Type}
                                    <span className="required-star">*</span>
                                </label>
                            </span>
                            <span className="separatortopncr">
                                <select id="ncr_clientType" name="Client Type" className="dropBox"
                                    title=""
                                    style={{ height: '20px', width: '300px', border: '1px solid #797979', fontSize: '11px !important', fontFamily: 'arial, sans-serif !important' }}>
                                    <option></option>
                                    {NewClientRegistrationText.ClientTypeOptions.map((option: any, index: number) => (<option value={NewClientRegistrationText.ClientTypeOptionValue[index]} key={index}>{option}</option>))}
                                </select>
                            </span>
                        </div>
                        <div className="separatortop">
                            <span > <label className="separatortopncr">{NewClientRegistrationText.NewClientRegistration.Salutation}</label></span>
                            <span className="separatortopncr">
                                <select id="ncr_salutation" name="Salutation" className="dropBox"
                                    title=""
                                    style={{ height: '20px', width: '300px', border: '1px solid #797979', fontSize: '11px !important', fontFamily: 'arial, sans-serif !important' }}>
                                    <option></option>
                                    {NewClientRegistrationText.SalutationOptions.map((option: any, index: number) => (<option value={option} key={index}>{option}</option>))}
                                </select>
                            </span>
                        </div>

                        {
                            (locale !== 'JA_JP') ?
                                <div className="separatortop">
                                    <span>
                                        <label className="separatortopncr">
                                            {NewClientRegistrationText.NewClientRegistration.First_Name}
                                            <span className="required-star">*</span>
                                        </label>
                                    </span>
                                    <span className="separatortopncr">
                                        <div style={{display: 'inline-block'}}>
                                            <div className="separatortop">
                                                <span style={{ marginRight: "13px" }}> <input className="inputTextStyle"
                                                    id="ncr_firstName" name="First Name" type="text" style={{ width: "145px" }} /></span>
                                                <span style={{ width: "120px", marginRight: "30px" }}>
                                                    <label>{NewClientRegistrationText.NewClientRegistration.Middle_Initial}</label>
                                                </span>
                                                <span> <input className="inputTextStyle"
                                                    id="ncr_mInitial" type="text" style={{ width: "30px" }} maxLength={1} /></span>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                :
                                <div className="separatortop">
                                    <span >
                                        <label className="separatortopncr">
                                            {NewClientRegistrationText.NewClientRegistration.First_Name}
                                            <span className="required-star">*</span>
                                        </label>
                                    </span>
                                    <span className="separatortopncr"><input className="inputTextStyle" id="ncr_firstName" name="First Name" type="text" style={{ width: "300px" }} />
                                    </span>
                                </div>
                        }
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.Last_Name}
                                    <span className="required-star">*</span>
                                </label>
                            </span>
                            <span className="separatortopncr"><input className="inputTextStyle"
                                id="ncr_lastName" name="Last Name" type="text" style={{ width: '300px' }} /></span>
                        </div>
                        <div className="separatortop">
                            <span > <label className="separatortopncr">{NewClientRegistrationText.NewClientRegistration.Professional_Title}</label></span>
                            <span className="separatortopncr"><input className="inputTextStyle"
                                id="ncr_proffTitle" name="Professional Title" type="text" style={{ width: '300px' }} /></span>
                        </div>
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.Company_Emai_Address}
                                    <span className="required-star">*</span>
                                </label>
                            </span>
                            <span className="separatortopncr"><input className="inputTextStyle"
                                id="ncr_companyEmail" name="Company Email Address" type="text" style={{ width: '300px' }}
                                value={this.state.emailAddress} readOnly disabled
                                /></span>
                        </div>
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.Telephone_Number}
                                    <span className="required-star">*</span>
                                </label>
                            </span>
                            <span className="separatortopncr"><input className="inputTextStyle"
                                id="ncr_telephoneNum" name="Telephone Number" type="text" style={{ width: '300px' }} /></span>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="sectionTextStyle">{NewClientRegistrationText.NewClientRegistration.COMPANY_INFORMATION}</div>
                    <div style={{ textAlign: 'left', paddingTop: '10px' }}>
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.Company_Name}
                                    <span className="required-star">*</span>
                                </label>
                            </span>
                            <span className="separatortopncr"><input className="inputTextStyle"
                                id="ncr_companyName" name="Company Name" type="text" style={{ width: '300px' }} /></span>
                        </div>
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.Mailing_Address}
                                    <span className="required-star">*</span>
                                </label>
                            </span>
                            <span className="separatortopncr"><input className="inputTextStyle"
                                id="ncr_mailingAddress" name="Mailing Address" type="text" style={{ width: '300px' }} /></span>
                        </div>
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.Mailing_Address_line_2}
                                </label>
                            </span>
                            <span className="separatortopncr"><input className="inputTextStyle"
                                id="ncr_mailingAddressLine2" name="Mailing address line 2" type="text" style={{ width: '300px' }} /></span>
                        </div>
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.City}
                                    <span className="required-star">*</span>
                                </label>
                            </span>
                            <span className="separatortopncr"><input className="inputTextStyle"
                                id="ncr_city" name="City" type="text" style={{ width: '300px' }} /></span>
                        </div>
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.State_Province}
                                </label>
                            </span>
                            <span className="separatortopncr"><input className="inputTextStyle"
                                id="ncr_state" name="State/Province" type="text" style={{ width: '300px' }} /></span>
                        </div>
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.Zip_Postal_Code}
                                    <span className="required-star">*</span>
                                </label>
                            </span>
                            <span className="separatortopncr"><input className="inputTextStyle"
                                id="ncr_zip" name="Zip/Postal Code" type="text" style={{ width: '300px' }} /></span>
                        </div>
                        <div className="separatortop">
                            <span >
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.Country}
                                    <span className="required-star">*</span>
                                </label>
                            </span>
                            <span className="separatortopncr">
                                <select id="ncr_country" name="Country" className="dropBox"
                                    title=""
                                    style={{ height: '20px', width: '300px', border: '1px solid #797979', fontSize: '11px !important', fontFamily: 'arial, sans-serif !important' }}>
                                    <option></option>
                                    {NewClientRegistrationText.CountryOption.map((option: any, index: number) => (<option value={NewClientRegistrationText.CountryOptionValue[index]} key={index}>{option}</option>))}
                                </select>
                            </span>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="sectionTextStyle">{NewClientRegistrationText.NewClientRegistration.CITI_CONTACT_INFORMATION}</div>
                    <div style={{ textAlign: 'left', paddingTop: '10px' }}>
                        <div className="separatortop">
                            <span> <label className="separatortopncr">{NewClientRegistrationText.NewClientRegistration.Citi_Contact_Name}</label></span>
                            <span className="separatortopncr"><input className="inputTextStyle" id="ncr_citiSalesContact" name="Citi Contact Name" type="text" style={{ width: '300px' }} /></span>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="sectionTextStyle">{NewClientRegistrationText.NewClientRegistration.CAPTCHA_VALIDATE}</div>
                    <div style={{ textAlign: 'left', paddingTop: '10px' }}>
                        <div className="separatortop">
                            <span>
                                <label className="separatortopncr">
                                    {NewClientRegistrationText.NewClientRegistration.Please_input_captcha}
                                    <span className="required-star">*</span>
                                </label>
                            </span>
                            <span className="separatortopncr" style={{ alignItems: 'center', justifyContent: 'space-between', width: 'auto' }}>
                                <input className="inputTextStyle" id="ncr_captcha" name="captcha" type="text" style={{ width: '90px' }} />
                                <img style={{ cursor: 'pointer' }} id="rendition-captcha" alt="rendition captcha"/>
                                <div id="captcha-refresh-button" className="captcha-refresh-button" ></div>
                            </span>
                            <span>
                                <span id="captcha-message" style={{ color: '#e60000', display: 'none' }}></span>
                            </span>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div style={{ paddingLeft: '20px' }}>{NewClientRegistrationText.NewClientRegistration.Submit_message}</div>
                    <br />
                    <div style={{ textAlign: 'left' }}>
                        <div className="separatortop">
                            <span className="separatortopncr">
                                <input type="button" className="buttonRepeat" id="ncr_submit"
                                    disabled={!this.state.preConditionBln}
                                    title={NewClientRegistrationText.NewClientRegistration.Submit_button}
                                    value={NewClientRegistrationText.NewClientRegistration.Submit_button}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="reset" className="buttonRepeat" id="ncr_reset" title={NewClientRegistrationText.NewClientRegistration.Reset_button} value={NewClientRegistrationText.NewClientRegistration.Reset_button} onClick={(e) => ncrReset()} style={{ width: '65px !important' }} />
                            </span>
                            { this.state.disabledSubmitBtn &&
                                <div className="loadable-state" id="react-loadable-loading"> <Loading /></div>
                            }
                            <span className="separatortopncr">
                            </span>
                        </div>
                    </div>
                    <br />
                </div>

            </div>
        );
    }
}

export default NewClientRegistration;
