import _ from 'lodash';
import { Base64 } from 'js-base64';

export const getUrlParameters = (url: string, splitKey: string) => {
    const paramObj: any = {};
    const urlSplits = url.split(splitKey);
    if (urlSplits.length == 0 || _.isEmpty(urlSplits[1])) {
        return null;
    }
    const params = urlSplits[1].split('&');
    if (params.length == 0) {
        return null;
    }

    _.forEach(params, (arr: string) => {
        paramObj[arr.split('=')[0]] = arr.split('=')[1];
    });

    return paramObj;
};

export const getUrlParameter = (splitKey: string, paramKey: string, endWith?: string) => {
    let paramValue = null;
    let url = window.location.href;
    if (!_.isEmpty(endWith)) {
        url = _.split(url, endWith)[0];
    }
    const paramArray = getUrlParameters(url, splitKey);

    if (!_.isEmpty(paramArray)) {
        if (!_.isEmpty(paramArray[paramKey])){
            paramValue = paramArray[paramKey];
        }
    }

    return paramValue;
};

export const addParam = (url: string, parameterName: string | boolean, parameterValue: string | boolean) => {
    if (parameterValue == undefined || parameterValue === "" || parameterValue == 'null') {
        return url;
    }
    url += (url.indexOf('?') != -1) ? "&" : "?";
    url += parameterName + "=" + parameterValue;
    return url;
};

export const strTrim = (inputStr: string) => {
    if (!inputStr) {
        return "";
    }
    return inputStr.replace(/^\s+|\s+$/g, '');
};

export const decodeBase64OriginURL = (originURL: string) => {
    let _tmpUrl = originURL.replace(/_/g, '/').replace(/-/g, '+');
    if (window.atob) {
        _tmpUrl = window.atob(_tmpUrl);
    } else {
        _tmpUrl = Base64.atob(_tmpUrl);
    }
    return _tmpUrl;
};
