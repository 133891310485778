import React from 'react';
import _ from 'lodash';

import { ENTexts, JPTexts } from './registration_i18n';

const Context = React.createContext({
        locale: 'EN_US',
        localeData: ENTexts,
        initialData: {}
    });

export class LanguageStore extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            locale: 'EN_US',
            localeData: ENTexts,
            initialData: {
                privacyStatement: '/zhvutneDE80d1ne%2Fczk97PJ8qGTS%2FaQsoMdOGX2r2JDKIGmQUgrCnlP4lCE9WLVbkWfCKtEM5%2Fg%3D',
                termOfUse: '/Zf0BNUIv2kQRw93hoMa7W5UCGaPtVyLZjqW6OV%2BkWUmh%2BzTCdRCxWlts5UKTT%2FQ%2FcxmJ0xqwkK8%3D',
                tokenEmailEnPngUrl: '/rendition/eppublic/akpublic/images/token-email.png',
                tokenEmailJpPngUrl: '/rendition/eppublic/akpublic/images/token-email-ja.png',
                helpJpLinkUrl: '/rendition/documents/help/akpublic/eppublic/Registration%20Guidelines%20Japanese.pdf',
                helpEnLinkUrl : '/rendition/documents/help/akpublic/eppublic/Registration%20Guidelines.pdf'
            }
        };

    }

    onLocaleChange = ( locale ) => {
        let tempLocale =  locale.toUpperCase();
        if (!_.isEmpty(tempLocale) && tempLocale === 'JA_JP') {
            this.setState ({...this.state, locale: tempLocale, localeData: JPTexts });           
        } else {
            this.setState ({...this.state, locale: tempLocale, localeData: ENTexts });           
        }
   };

    render () {
        return (
            <Context.Provider 
                value = {{...this.state, onLocaleChange: this.onLocaleChange  }}
            >
               { this.props.children }
            </Context.Provider>
        );
    }
}

export default Context;