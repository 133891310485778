import React, { PureComponent } from 'react';
import history from '../history';
import _ from 'lodash';
import RegistrationContext from '../store/registrationContext';
import LanguageHeader from './languageHeader';
import ResponseErrorDetails from './responseErrorDetails';
import { postDeviceRegistration, checkEvercookieInBrowser, resetDeviceIdBasedonEvercookieValue } from '../services/axios-services';
import { getUrlParameter, decodeBase64OriginURL } from '../utils/registrationUtils';
import Loading from '../utils/loading';

interface IDeviceRegistrationStatus {
    locale: string;
    originURL: string;
    emailAddress: string;
    deviceCookieName: string;
    errorMsg: string;
    preConditionBln: boolean;
    disabledRegisterBtn: boolean;
    responseErrorBln: boolean;
    errorDetail: {};
}

class DeviceRegistration extends PureComponent<any, IDeviceRegistrationStatus> {

    public static contextType = RegistrationContext;

    constructor(props: any) {
        super(props);

        let localeParam = getUrlParameter('?', 'locale', '#/');
        const originURL = getUrlParameter('?', 'originURL', '#/');
        const deviceCookieName = getUrlParameter('?', 'deviceCookieName', '#/');

        if (_.isEmpty(localeParam)) {
            localeParam = 'EN_US';
        }

        this.state = {
            locale: localeParam,
            originURL,
            emailAddress: '',
            deviceCookieName,
            errorMsg: '',
            preConditionBln: true,
            disabledRegisterBtn: false,
            responseErrorBln: false,
            errorDetail: {}
        };

    }

    public async componentWillMount() {
        if (_.isEmpty(this.state.deviceCookieName) || _.isEmpty(this.state.originURL)) {
            return;
        }

        const cookieValueInEvercookie = await checkEvercookieInBrowser(this.state.deviceCookieName);
        console.log('cookieValueInEvercookie=' + cookieValueInEvercookie);

        if (!_.isEmpty(cookieValueInEvercookie)) {
            const resetDeviceId = await resetDeviceIdBasedonEvercookieValue(cookieValueInEvercookie);
            console.log('resetDeviceId=' + resetDeviceId);

            if (!_.isEmpty(resetDeviceId)) {
                window.location.href = decodeBase64OriginURL(this.state.originURL);
                return;
            }

        }
    }

    public componentDidMount() {
        this.context.onLocaleChange(this.state.locale);

        const preConditionErrors = this.validtePreConditions();
        if (!_.isEmpty(preConditionErrors)) {
          console.error('DeviceRegistration:: Precondition=', preConditionErrors);
          this.setState({
            preConditionBln: false,
            disabledRegisterBtn: true
          });
          return;
        }
    }

    public validtePreConditions = () => {
        const errors: any = {};
        if (_.isEmpty(this.state.originURL)) {
          errors.deviceToken = 'No originURL in request url params for Device Registration ';
        }
        return errors;
      };

    public onFormSubmit = async (event: any) => {
        event.preventDefault();
        const { DeviceRegistrationText } = this.context.localeData;

        const errorMsg = this.validateEmail(this.state.emailAddress);

        if (!_.isEmpty(errorMsg)) {
            this.setState({ errorMsg });
            return;
        }

        this.setState({disabledRegisterBtn: true});

        const payloadData= {
            originURL: this.state.originURL,
            emailAddress: this.state.emailAddress,
            locale: this.context.locale
        };

        const resp: any = await postDeviceRegistration(payloadData);

        console.log('DeviceRegistration onFormSubmit status=', resp);

        if (resp && 200 === resp.status) {
            history.push('/deviceRegistrationSuccess');
        } else {
            console.log('DeviceRegistrationConfirmation:: Error=', resp);
            let requestId = resp.requestId;
            if (_.isEmpty(requestId)) {
                requestId = !_.isEmpty(resp.data.errorDetail) ?  resp.data.errorDetail.errorId : 'None';
            }
            const message = DeviceRegistrationText.ResponseErrorMessage;
            this.setState({
                disabledRegisterBtn: false,
                responseErrorBln: true,
                errorDetail: {
                    requestId,
                    message
                }
            });
        }

    };

    public validateEmail = (emailAddress: string) => {
        const { DeviceRegistrationText } = this.context.localeData;
        const emailRegEx = /^[A-Z0-9._%+'-]+@[A-Z0-9.-]+\.[A-Z]{1,10}$/i;

        let errorMsg: string = '';
        if (!emailAddress) {
            errorMsg = DeviceRegistrationText.ValiateEmailAddressRequired;
        } else if (emailAddress.search(emailRegEx) == -1) {
            errorMsg = DeviceRegistrationText.EmailAddressInvalid;
        }
        return errorMsg;
    };

    public downloadHelp = () => {
        const { DeviceRegistrationText } = this.context.localeData;
        const { locale } = this.context;
        const { helpEnLinkUrl, helpJpLinkUrl } = this.context.initialData;

        const helpLink = locale === 'JA_JP' ? helpJpLinkUrl :  helpEnLinkUrl;

        return (
            <span className="download">
                <a tabIndex={0} href={helpLink} target="_blank">{DeviceRegistrationText.guideLink}</a>
            </span>
        );
    }

    public renderDom() {
        const { DeviceRegistrationText } = this.context.localeData;
        return (
            <div id="deviceRegistration">

                <div>

                    <LanguageHeader headerTitle={DeviceRegistrationText.title} />

                    {!this.state.preConditionBln &&
                        <div className="alert alert-danger">
                            <h6>
                            {_.isEmpty(this.state.originURL) && DeviceRegistrationText.NoOriginURL}
                            </h6>
                        </div>
                    }

                    {this.state.responseErrorBln &&
                        <ResponseErrorDetails errorDetail={this.state.errorDetail} />
                    }

                    <div className="content">
                        {DeviceRegistrationText.content1}
                        <span className="text-decoration-undline">{DeviceRegistrationText.content2}</span>
                        {DeviceRegistrationText.content3}
                    </div>

                    <div className="content form">

                        <form name="deviceRegistrationForm" onSubmit={this.onFormSubmit} >
                            <div className="form-content">
                                <span>
                                    <input type="text" name="emailAddress" className="email-address"
                                        placeholder={DeviceRegistrationText.inputLabel}
                                        value={this.state.emailAddress}
                                        onChange={(e) => this.setState({ emailAddress: e.target.value })}
                                    />
                                </span>
                                <span>
                                    <button id="deviceRegisterButton" className="register-btn" disabled={this.state.disabledRegisterBtn}>
                                        {DeviceRegistrationText.submit}
                                    </button>
                                    { this.state.disabledRegisterBtn &&
                                        <div className="loadable-state" id="react-loadable-loading"> <Loading /></div>
                                    }
                                </span>

                            </div>
                            <div className="error message">
                                <div className="header">{this.state.errorMsg}</div>
                            </div>
                        </form>
                    </div>

                    <div className="content guide">
                        <span className="font-weight-bold">
                            {DeviceRegistrationText.guideTextStart}
                        </span>
                        <span>{DeviceRegistrationText.guideTextStart1}</span>
                        {this.downloadHelp()}
                        <span>{DeviceRegistrationText.guideTextEnd}</span>
                    </div>

                    <div className="content notice">
                        <span> {DeviceRegistrationText.notciti3}</span>
                        <a tabIndex={0} target="_parent" href="mailto:rschaccess@citi.com" className="email-link">rschaccess@citi.com</a>
                    </div>
                </div>

            </div>

        );
    }

    public render() {
        return this.renderDom();
    }
}

export default DeviceRegistration;
