import React from 'react';

import RegistrationContext from '../store/registrationContext';
import LanguageHeader from './languageHeader';

export class DeviceRegistrationSuccess extends React.Component {

  public static contextType = RegistrationContext;

  public renderDom() {
    const { locale } = this.context;
    const { DeviceRegistrationSuccessText } = this.context.localeData;

    return (
      <div id="deviceRegistrationConfirm">
        <div>

          <LanguageHeader headerTitle={DeviceRegistrationSuccessText.title} />

          <div className="row content-info">

            <div className="col-sm-12 ">
              <div className="content-info-text">
                {DeviceRegistrationSuccessText.content1}
              </div>
              <ol className="content-info-text">
                <li>
                  {DeviceRegistrationSuccessText.content5}
                  <a className="text-decoratin-undline" href="mailto:rschaccess@citi.com">rschaccess@citi.com</a>
                </li>
                <li>
                  {DeviceRegistrationSuccessText.content6}
                </li>
              </ol>
              <div className="content-info-text">
                <p>
                  {DeviceRegistrationSuccessText.content2}
                </p>
                <p>
                  {DeviceRegistrationSuccessText.content21}
                  <a target="_parent" href="mailto:rschaccess@citi.com" className="email-link">rschaccess@citi.com</a>
                  <span>{DeviceRegistrationSuccessText.content4}</span>
                </p>
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }

  public render() {
    return this.renderDom();
  }

}

export default (DeviceRegistrationSuccess);
