import React, { Component } from 'react';
import '../../../style/animation/loading.scss';
import _ from 'lodash';

interface ILoadingProps {
  scale?: number;
  overrideStyle?: any;
  showLoadingBackground?: boolean;
}

interface ILoadingStates {
  style: any;
}

export class Loading extends Component<ILoadingProps, ILoadingStates> {

  constructor(props: ILoadingProps) {
    super(props);
    const overrideStyle = {
      transform: 'scale(' + this.props.scale + ')',
      WebkitTransform: 'scale(' + this.props.scale + ')',
      msTransform: 'scale(' + this.props.scale + ')',
      MozTransform: 'scale(' + this.props.scale + ')',
      OTransform: 'scale(' + this.props.scale + ')',
    };
    if (!_.isEmpty(this.props.overrideStyle)) {
      Object.assign(overrideStyle, this.props.overrideStyle);
    }
    this.state = {
      style: overrideStyle
    };
  }

  public render() {
    return (
      <React.Fragment>
        <div className='lds-spinner' style={this.state.style}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        {this.props.showLoadingBackground &&
          <div className='loading-bg' style={{zIndex: 1040, backgroundColor: '#000', opacity: 0.2}}/>
        }
      </React.Fragment>);
  }
}

export default Loading;
