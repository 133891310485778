import $ from 'jquery';
import _ from 'lodash';
import history from '../history';
import { validateFormFields, japMessages, validateAllRequiredFields } from './validator';
import { strTrim } from '../utils/registrationUtils';

export const Captcha = function (){
    Captcha.prototype._captcha();
};
Captcha.prototype = {
    _captcha() {
        const me = this;
        me.isHuman = false;

        me.initCaptcha();
    },

    initCaptcha() {
        $('#rendition-captcha').unbind('click').click(() => {
            this.initCaptcha();
        });
        $('#captcha-refresh-button').unbind('click').click(() => {
            this.initCaptcha();
        });
        $('#ncr_captcha').unbind('blur').blur((e) => {
            this.preValidateCaptcha();
        });
        this._setupCaptcha();
    },

    preValidateCaptcha(){
        const me = this;
        me.captchaValue = $('#ncr_captcha').val();
        if (!me.captchaValue){
            $('#captcha-message').text('Captcha is empty').show();
//            $('#ncr_captcha').focus();
            $('#ncr_submit').attr('disabled', 'disabled');
            return;
        } else {
            $('#captcha-message').hide();
        }

        const captchaDetail = {
            key: me.key,
            value: me.captchaValue
        };

        $.ajax({
            url: '/foundation/captchams/eppublic/V1/captcha.json?ts=' + new Date().getTime(),
            traditional: false,
            type: 'POST',
            async: false,
            beforeSend: (xhr) => {
                console.log(xhr);
            },
            headers: {
                'callerId': 'REGISTRATION_PAGE',
                'Accept': 'application/json'
            },
            complete: (xhr, ts) => {
                console.log(xhr);
            },
            contentType: 'application/json;charset=UTF-8',
            data: JSON.stringify(captchaDetail),
            cache: false,
            success: (res: any) => {
                me._successCaptcha();
            },
            error: (res: any) => {
                me._errorCaptcha();
            }
        });

        return me.isHuman;
    },
    _setupCaptcha() {
        const me = this;
        $('#ncr_captcha').val('');
        this.isHuman = false;

        $.ajax({
            url: '/foundation/captchams/eppublic/V1/captcha.json?ts=' + new Date().getTime(),
            traditional: false,
            type: 'GET',
            async: false,
            beforeSend: (xhr) => {
                console.log(xhr);
            },
            headers: {
                'callerId': 'REGISTRATION_PAGE',
                'Accept': 'application/json'
            },
            complete: (xhr, ts) => {
                console.log(xhr);
            },
            contentType: 'application/json;charset=UTF-8',
            cache: false,
            success: (res: any) => {
                const imgStr = res.image;
                $('#rendition-captcha').attr('src', 'data:image/png;base64,' + imgStr);
                me.key = res.key;
                me.host = '';
            },
            error: (res: any) => {
                console.log('Error: get Captcha=', res);
            }
        });
    },
    _successCaptcha() {
        $('#captcha-message').hide();
        $('#ncr_submit').removeAttr('disabled');
        this.isHuman = true;
    },
    _errorCaptcha() {
        this.initCaptcha();
        $('#captcha-message').text('Captcha is not correct').show();
//        $('#ncr_captcha').focus();
        $('#ncr_submit').attr('disabled', 'disabled');
    }
};

const showMask = () => {
  $('body').addClass('loadmask-body').append('<div class="loadmask"></div>');
  $('#outerContainer-reg').addClass('masked');
};

const hideMask = () => {
  $('body').removeClass('loadmask-body').find('.loadmask').remove();
  $('#outerContainer-reg').removeClass('masked');
};

export const initialExecute = () => {
    const captcha = new (Captcha as any)();
    let captchaValue: any;
    let key: any;
    let host: any;
    $('#ncr_submit').click(function () {

        captchaValue = captcha.captchaValue;
        key = captcha.key;
        host = captcha.host;

        const locale = $("#ncr_locale").val();

        if (!validateAllRequiredFields()) {
            if (locale == "JA_JP") {
                $("#error").text(japMessages['mandatory']);
            } else {
                $("#error").text("Please fill in all required fields, Thank you.");
            }
            $('html, body').scrollTop(0);
            return;
        }

        const newClientRegistrationId = $('#newClientRegistrationId').val();
        const companyEmail = $("#ncr_companyEmail").val();

        const inData = {
            "emailAddress": companyEmail,
            "salutation": $('#ncr_salutation').val(),
            "position": $('#ncr_position').val(),
            "clientType": $('#ncr_clientType').val(),
            "country": $('#ncr_country').val(),
            "firstName": strTrim($('#ncr_firstName').val()?.toString() || ''),
            "lastName": strTrim($('#ncr_lastName').val()?.toString() || ''),
            "mInitial": strTrim($('#ncr_mInitial').val() ?.toString() || ''),
            "title": strTrim($('#ncr_proffTitle').val()?.toString() || ''),
            "companyName": strTrim($('#ncr_companyName').val()?.toString() || ''),
            "mailingAddress": strTrim($('#ncr_mailingAddress').val()?.toString() || ''),
            "mailingAddressLine2": strTrim($('#ncr_mailingAddressLine2').val()?.toString() || ''),
            "city": strTrim($('#ncr_city').val()?.toString() || ''),
            "state": strTrim($('#ncr_state').val()?.toString() || ''),
            "zip": strTrim($('#ncr_zip').val()?.toString() || ''),
            "telephoneNum": strTrim($('#ncr_telephoneNum').val()?.toString() || ''),
            "citiSalesContact": strTrim($('#ncr_citiSalesContact').val()?.toString() || ''),
            "newClientReg": "Y",
            "locale": locale
        };

        const formData = {
            newClientRegistrationId,
            personalInformation: {
                position: inData.position,
                clientType: inData.clientType,
                salutation: inData.salutation,
                firstName: inData.firstName,
                middleInitial: inData.mInitial,
                lastName: inData.lastName,
                professionalTitle: inData.title,
                companyEmailAddress: inData.emailAddress,
                phoneNumber: inData.telephoneNum
            },
            companyInformation: {
                companyName: inData.companyName,
                mailingAddress1: inData.mailingAddress,
                mailingAddress2: inData.mailingAddressLine2,
                cityTown: inData.city,
                stateProvince: inData.state,
                zipPostalCode: inData.zip,
                country: inData.country
            },
            citiContactName: inData.citiSalesContact,
            captchaDetails: {
                key,
                value: captchaValue
            },
            locale
        };

        if (validateFormFields(inData)) {
            $('#ncr_submit').attr('disabled', 'disabled');
            $.ajax({
                url: '/rendition/registrationws/eppublic/V1/newClientRegistration.json?ts=' + new Date().getTime(),
                traditional: false,
                beforeSend: (xhr) => {
                    showMask();
                },
                headers: {
                    'callerId': 'NEW_CLIENT_REGISTRATION_PAGE',
                    'accept': 'application/json'
                },
                complete: (xhr, ts) => {
                    console.error('createQueueUser error', ts);
                    hideMask();
                    captcha.initCaptcha();
                },
                type: 'POST',
                contentType: 'application/json;charset=UTF-8',
                data: JSON.stringify(formData),
                cache: false,
                success: (res) => {
                    history.push('/newClientRegistrationSuccess');
                },
                error: (res: any) => {
                    const respRequstId = res.getResponseHeader('x-citiportal-requestid');
                    console.log('Error:  newClientRegistrations=', res);
                    let resp: any = null;

                    const respStatus: string =  JSON.stringify(res.status);
                    if ( !_.isEmpty(respStatus) && respStatus == '404' ) {
                        history.push('/newClientRegistrationError');
                        return;
                    }

                    try {
                        resp = JSON.parse(res.responseText);
                    } catch (e) {
                        resp = res.responseText;
                    }

                    if (typeof resp === 'string' ) {
                        if (locale == "JA_JP") {
                            $("#error").text("エラーが発生しました。再度お試しください。 " + JSON.stringify(resp));
                        } else {
                            $("#error").text("Error occurred, please try again. "  + JSON.stringify(resp));
                        }
                    }

                    if (typeof resp === 'object' ) {
                        let errorId = respRequstId;
                        if (_.isEmpty(respRequstId)) {
                            errorId = !_.isEmpty(resp.errorDetail) ?   resp.errorDetail.errorId : 'None';
                        }

                        if (locale == "JA_JP") {
                            $("#error").text("新しいクライアント情報を登録しようとしたときに問題が発生しました。 もう一度試すか、シティの担当者にお問い合わせください。 RequestId: " + errorId );
                        } else {
                            $("#error").text("A problem occurred while attempting to register new Client Info. Please try again or contact your Citi representative. RequestId: "  + errorId );
                        }
                    }

                    $('#ncr_submit').removeAttr('disabled');
                    $('html, body').scrollTop(0);

                }

            });
        } else {
            $('html, body').scrollTop(0);
            return;
        }
    });
};

export const ncrReset = () => {
    $('#ncr_captcha').val("");
    $('#rendition-captcha').click();
    $('#ncr_position').val("");
    $('#ncr_clientType').val("");
    $('#ncr_country').val("");
    $('#ncr_firstName').val("");
    $('#ncr_lastName').val("");
    $('#ncr_proffTitle').val("");
    $('#ncr_companyName').val("");
    $('#ncr_mailingAddress').val("");
    $('#ncr_mailingAddressLine2').val("");
    $('#ncr_city').val("");
    $('#ncr_state').val("");
    $('#ncr_zip').val("");
    $("#ncr_telephoneNum").val("");
    $('#ncr_mInitial').val("");
    $('#ncr_salutation').val("");
    $('#ncr_citiSalesContact').val("");
};

const getNowTimeString = () => {
    const startTimeObj = new Date();
    let startTime = startTimeObj.getFullYear().toString() + "_";
    if (startTimeObj.getMonth().toString().length <= 1) {
        startTime += "0";
    }
    startTime = startTime + startTimeObj.getMonth().toString() + "_";
    if (startTimeObj.getDate().toString().length <= 1) {
        startTime += "0";
    }
    startTime = startTime + startTimeObj.getDate().toString() + "_";
    if (startTimeObj.getHours().toString().length <= 1) {
        startTime += "0";
    }
    startTime = startTime + startTimeObj.getHours().toString() + "_";
    if (startTimeObj.getMinutes().toString().length <= 1) {
        startTime += "0";
    }
    startTime = startTime + startTimeObj.getMinutes().toString() + "_";
    if (startTimeObj.getSeconds().toString().length <= 1) {
        startTime += "0";
    }
    startTime = startTime + startTimeObj.getSeconds().toString() + "_";
    if (startTimeObj.getMilliseconds().toString().length <= 2) {
        let count = 3 - startTimeObj.getMilliseconds().toString().length;
        while (count > 0) {
            startTime += "0";
            count--;
        }
    }
    startTime = startTime + startTimeObj.getMilliseconds().toString() + "_";
    return startTime;
};

const reloadDropDown2 = (compId: any, dataRows: any, name: any, value: any, loadingDiv: any, isAddBlank: any, customBlankName: any, cropLength: any) => {
    reloadDropDownWithDisabledItem(compId, dataRows, name, value, loadingDiv, isAddBlank, customBlankName, cropLength, null);
};

const reloadDropDown = (compId: any, dataRows: any, name: any, value: any, loadingDiv: any, isAddBlank: any) => {
    reloadDropDown2(compId, dataRows, name, value, loadingDiv, isAddBlank, "", null);
};

const reloadDropDownWithDisabledItem = (compId: any, dataRows: any, name: any, value: any, loadingDiv: any, isAddBlank: any, customBlankName: any, cropLength: any, disabledDataList: any) => {
    if (dataRows != null) {
        $(compId).find('option').remove().end();

        // Add blank option
        if (isAddBlank == true && !(!dataRows[0][name] || /^\s*$/.test(dataRows[0][name]))) {
            $(compId).prepend("<option value=''>" + customBlankName + "</option>");
        }

        for (let i = 0; i < dataRows.length; i++) {
            let displayId = i;
            if (value != null) {	// For cache mode.
                displayId = dataRows[i][value];
            }
            // noData = false;
            const originalName = dataRows[i][name];
            let displayName = dataRows[i][name];
            if (cropLength != null) {
                if (displayName.length > cropLength) {
                    displayName = displayName.substring(0, cropLength) + "...";
                    // If displayName is different you have to retrieve again
                }
            }
            let disabled = "";
            if (disabledDataList != null) {
                if (disabledDataList[displayId] == displayId) {
                    disabled = " disabled=disabled ";
                }
            }
            let isSystem = "";
            if (dataRows[i].IS_SYSTEM == "Y") {
                isSystem = " issystem='TRUE' ";
            }
            // ENT_ACTION for entitlement component only
            let entAction = "";
            if (compId == "#existEntitlement") {
                if (dataRows[i].ENT_ACTION == "1") {
                    entAction = " entAction='1' ";
                } else {
                    entAction = " entAction='0' ";
                }
            }
            $(compId).append('<option value="' + displayId + '" ' + isSystem + ' originname="' + originalName + '" ' + entAction + disabled + ' >' + displayName + '</option>');
        }
    }
    if (loadingDiv != null) {
        $(loadingDiv).hide();
    }
};
