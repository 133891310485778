import React from 'react';
import RegistrationContext from '../store/registrationContext';

export class RegistrationFooter extends React.Component {

  public static contextType = RegistrationContext;

  constructor(props: any) {
    super(props);
  }

  public openPrivacyStatementOrTermOfUse = (linkType: string, params: string) => {

    const irEnv = window.location.host.indexOf('www.citivelocity.com') < 0 ? 'https://uat.ir.citi.com' : 'https://ir.citi.com';
    if (linkType === 'PrimacyStatement') {
      if (window.location.href.split('&').indexOf('language=ja') > -1) {
        params += '?jp';
      } else if (this.context.locale === 'JA_JP') {
        params += '?jp';
      }
    }

    window.location.href = irEnv + params;
  };

  public renderDom() {
    const { FooterText } = this.context.localeData;

    return (
      <div id="registrationFooter" >
        <div className="footer-links">
          <span>
            <a href={'mailto:rschaccess@citi.com'} target="_top">
              {FooterText.CONTACT_SUPPORT}
            </a>
          </span>

          <span> | </span>

          <span>
            <a onClick={(e) => this.openPrivacyStatementOrTermOfUse('PrimacyStatement', this.context.initialData.privacyStatement)}
            >
              {FooterText.PrivacyStatement}
            </a>
          </span>

          <span> | </span>

          <span>
            <a onClick={(e) => this.openPrivacyStatementOrTermOfUse('termOfUse', this.context.initialData.termOfUse)}
            >
              {FooterText.TermsOfUse}
            </a>
          </span>
        </div>

        <div id="footerCopyright" style={{ textAlign: 'center', width: '100%', paddingLeft: '20px' }} >
          {FooterText.Copyright_1 + new Date().getUTCFullYear() + FooterText.Copyright_2}
        </div>
      </div>
    );
  }

  public render() {
    return this.renderDom();
  }
}

export default RegistrationFooter;
