import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import RegistrationContainer from './js/registration/registrationContainer';
import Framebust from './js/registration/components/framebust';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/newRebranding/registration/registration.scss';

const isAppInsideIFrame = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return false;
    }
};

let showingDOM = null;
if (isAppInsideIFrame()) {
    showingDOM = <Framebust />;
} else {
    showingDOM = <RegistrationContainer />;
}

ReactDOM.render(
    showingDOM,
    document.getElementById('root'),
);
