import React from 'react';

export const Header = () => {
  return (
    <div id="mainHeader">
        <div className='citi_logo' role="img" aria-label="citi logo"></div>
        <div className='citi_research_text'>Citi Research</div>  
    </div>
  );
};

export default Header;
