import React from 'react';
import _ from 'lodash';
import RegistrationContext from '../store/registrationContext';
import LanguageHeader from './languageHeader';
import { getUrlParameter } from '../utils/registrationUtils';

interface IEasyLinkRegistrationUrlErrorStatus {
  locale: string;
  originURL: string;
}

export class EasyLinkRegistrationUrlError extends React.PureComponent<any, IEasyLinkRegistrationUrlErrorStatus> {

  public static contextType = RegistrationContext;

  constructor(props: any) {
    super(props);

    let localeParam = getUrlParameter('/easyLinkRegistrationUrlError?', 'locale');
    const originURL = getUrlParameter('/easyLinkRegistrationUrlError?', 'originURL');

    if (_.isEmpty(originURL)) {
      console.error("No originURL for this new client registratoin:");
    }

    if (_.isEmpty(localeParam)) {
      localeParam = 'EN_US';
    }

    this.state = {
      locale: localeParam,
      originURL
    };

  }

  public redirectHomePage = (event: any) => {
    event.preventDefault();
    const homeUrl = window.location.origin;
    window.open(homeUrl, '_self');
  }

  public renderDom() {

    return (
      <div id="easyLinkRegistrationUrlError">

        <LanguageHeader headerTitle={''} />

        <div className="alert alert-warning">
          <h5 className="mb-0">
            {'The link you are accessing has expired. Please contact support at:'} <a href="mailto:rschaccess@citi.com" target="_top">rschaccess@citi.com</a>
          </h5>
        </div>

      </div>
    );
  }

  public render() {
    return this.renderDom();
  }

}

export default (EasyLinkRegistrationUrlError);
