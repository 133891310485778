import $ from 'jquery';
import _ from 'lodash';
import { strTrim } from '../utils/registrationUtils';

const nameRegEx = /^[a-zA-Z'\s\.\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]{1,30}$/;
const telephoneRegEx = /^[+\d-#()\s]{1,20}$/;
const companyRegEx = /^[a-zA-Z0-9'#-\s\.\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]{1,30}$/;
const mailingAddressRegEx = /^[a-zA-Z0-9'/&()#-\s\.\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]{1,30}$/;
const mailingAddress2RegEx = /^[a-zA-Z0-9'/&()#-\s\.\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]{0,30}$/;
const cityRegEx = /^[a-zA-Z\s\.\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]{1,20}$/;
const stateRegEx = /^[a-zA-Z\s\.\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]{0,20}$/;
const zipRegEx = /^[a-zA-Z0-9-\s]{1,20}$/;
const clientRegEx = /^[a-zA-Z/\s\.\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]{1,30}$/;
const positionRegEx = /^[a-zA-Z&()\.\s\/\-\_\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]{0,50}$/;
const titleRegEx = /^[a-zA-Z'\s\.\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]{0,30}$/;

const nameArray = ['firstName', 'lastName'];
const telephoneArray = ['telephoneNum'];
const companyArray = ['companyName'];
const mailingArray = ['mailingAddress'];
const mailing2Array = ['mailingAddressLine2'];
const cityArray = ['city'];
const stateArray = ['state'];
const zipArray = ['zip'];
const clientArray = ['clientType', 'country'];
const positionArray = ['position', 'salutation'];
const titleArray = ['title', 'citiSalesContact'];
const keyTagMap: any = { "companyName": "Company Name", "title": "Professional Title" };
let length = 30;
export let japMessages: any = {
    firstName: '名前が正しくありません。正しい名前を入力してください。',
    lastName: '名前が正しくありません。正しい名前を入力してください。',
    emailAddress: 'メールアドレスが正しくありません。正しいメールアドレスを入力してください。',
    telephoneNum: '電話番号が正しくありません。正しい電話番号を入力してください。',
    companyName: '社名が正しくありません。正しい社名を入力してください。',
    mailingAddress: '住所が正しくありません。正しい住所を入力してください。',
    mailingAddressLine2: '住所が正しくありません。正しい住所を入力してください。',
    city: '市名が正しくありません。正しい市名を入力してください。',
    state: '都道府県名が正しくありません。正しい都道府県名を入力してください。',
    zip: '郵便番号が正しくありません。正しい郵便番号を入力してください。',
    citiSalesContact: 'シティの担当者が正しくありません。正しい担当者を入力してください。',
    title: '役職名が正しくありません。正しい役職名を入力してください。',
    mandatory: 'すべての必須フィールドに記入してください、ありがとうございます。',
    matchEmail: '入力された会社のメールアドレスが一致しません。',
};
export const validateFormFields = (data: any) => {
    let returnVal = false;
    if (data != null && data instanceof Object) {
        _.forOwn(data, (value, key) => {
            if (value != null && value != undefined && value != '') {
                if ($.inArray(key, nameArray) != -1) {
                    returnVal = nameRegEx.test(value);
                    length = 30;
                }
                if ($.inArray(key, telephoneArray) != -1) {
                    returnVal = telephoneRegEx.test(value);
                    length = 20;
                }
                if ($.inArray(key, companyArray) != -1) {
                    returnVal = companyRegEx.test(value);
                    length = 30;
                }
                if ($.inArray(key, mailingArray) != -1) {
                    returnVal = mailingAddressRegEx.test(value);
                    length = 30;
                }
                if ($.inArray(key, mailing2Array) != -1) {
                    returnVal = mailingAddress2RegEx.test(value);
                    length = 30;
                }
                if ($.inArray(key, cityArray) != -1) {
                    returnVal = cityRegEx.test(value);
                    length = 30;
                }
                if ($.inArray(key, stateArray) != -1) {
                    returnVal = stateRegEx.test(value);
                    length = 2;
                }
                if ($.inArray(key, zipArray) != -1) {
                    returnVal = zipRegEx.test(value);
                    length = 2;
                }
                if ($.inArray(key, clientArray) != -1) {
                    returnVal = clientRegEx.test(value);
                    length = 2;
                }
                if ($.inArray(key, positionArray) != -1) {
                    returnVal = positionRegEx.test(value);
                    length = 2;
                }
                if ($.inArray(key, titleArray) != -1) {
                    returnVal = titleRegEx.test(value);
                    length = 2;
                }
                if (key == 'emailAddress') {
                    returnVal = validateEmail(value);
                    length = 30;
                }
                if (!returnVal && key != 'modified_by') {
                    const tagName = ($("#ncr_" + key) != null && $("#ncr_" + key).length > 0) ? $("#ncr_" + key).attr('name') : keyTagMap[key];
                    if (data.locale !== 'JA_JP') {
                        $("#error").text("Invalid " + tagName + ". Please Enter Valid " + tagName + ".");
                    } else {
                        $("#error").text(japMessages[key] != null ? japMessages[key] : "Invalid " + tagName + ". Please Enter Valid " + tagName + ".");
                    }
                    return false;
                }
            }

        });
    }
    return returnVal;
};

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const validateAllRequiredFields = () => {
    if (/^\s*$/.test($('#ncr_clientType').val() ?.toString() || '')
        || /^\s*$/.test($('#ncr_country').val() ?.toString() || '')
        || strTrim($('#ncr_firstName').val() ?.toString() || '') == ""
        || strTrim($('#ncr_lastName').val() ?.toString() || '') == ""
        || strTrim($('#ncr_telephoneNum').val() ?.toString() || '') == ""
        || strTrim($('#ncr_companyName').val() ?.toString() || '') == ""
        || strTrim($('#ncr_mailingAddress').val() ?.toString() || '') == ""
        || strTrim($('#ncr_city').val() ?.toString() || '') == ""
        || strTrim($('#ncr_zip').val() ?.toString() || '') == ""
        || strTrim($('#ncr_captcha').val() ?.toString() || '') == ""
    ) {
       return false;
    } else {
        return true;
    }
};

const validateNumber = (number: string) => {
    return /^[0-9]+$/.test(number);
};
