import React from 'react';
import _ from 'lodash';

import RegistrationContext from '../store/registrationContext';

interface ILanguageHeaderProps {
    headerTitle: string;
    headerTitleClassName?: string;
}

export class LanguageHeader extends React.Component<ILanguageHeaderProps> {
    public static contextType = RegistrationContext;

    constructor(props: ILanguageHeaderProps) {
        super(props);
    }

    public translateLink = (langType: string) => {
        const { LanguageHeaderText } = this.context.localeData;
        const langText = langType === 'JA_JP' ? LanguageHeaderText.japanese : LanguageHeaderText.english;
        return (
            <span className="translate-link"  >
                    <a onClick={() => this.context.onLocaleChange(langType)} >{langText}</a>
            </span>
        );
    }

    public render() {
        const titleClassName = _.isEmpty(this.props.headerTitleClassName) ? 'sub-header-text' : `sub-header-text ${this.props.headerTitleClassName}`;

        return (
            <div id="subHeader" className="clearfix" >
                <span className="language-bar">
                    {this.translateLink('EN_US')}
                    <span> | </span>
                    {this.translateLink('JA_JP')}
                </span>
                <span className={titleClassName}>
                    {this.props.headerTitle}
                </span>
            </div>
        );
    }
}

export default LanguageHeader;
