import React from 'react';
import _ from 'lodash';
import history from './../history';
import { Base64 } from 'js-base64';
import { postDeviceRegisrationConfirmation } from '../services/axios-services';
import { getUrlParameter } from '../utils/registrationUtils';
import RegistrationContext from '../store/registrationContext';
import ResponseErrorDetails from './responseErrorDetails';
import Loading from '../utils/loading';

interface IDeviceRegistrationConfirmationStatus {
  locale: string;
  deviceToken: string;
  originURL: string;
  easyLink: string;
  preConditionBln: boolean;
  responseSuccessBln: boolean;
  responseErrorBln: boolean;
  errorDetail: {};
}

class DeviceRegistrationConfirmation extends React.Component<any, IDeviceRegistrationConfirmationStatus> {

  public static contextType = RegistrationContext;

  constructor(props: any) {
    super(props);

    const deviceToken = getUrlParameter('?', 'deviceToken', '#/deviceRegistrationConfirmation');
    const originURL = getUrlParameter('?', 'originURL', '#/deviceRegistrationConfirmation');
    let localeParam = getUrlParameter('?', 'locale', '#/deviceRegistrationConfirmation');
    let easyLink = getUrlParameter('?', 'easyLink', '#/deviceRegistrationConfirmation');
    if (_.isEmpty(easyLink)) {
      easyLink = '';
    }
    if (_.isEmpty(localeParam)) {
      localeParam = 'EN_US';
    }

    this.state = {
      locale: localeParam,
      deviceToken,
      originURL,
      easyLink,
      preConditionBln: true,
      responseSuccessBln: false,
      responseErrorBln: false,
      errorDetail: {}
    };

  }

  public async componentDidMount() {
    this.context.onLocaleChange(this.state.locale);

    const { DeviceRegistrationConfirmationText } = this.context.localeData;

    const preConditionErrors = this.validtePreConditions();
    if (!_.isEmpty(preConditionErrors)) {
      console.log('DeviceRegistrationConfirmation:: Precondition=', preConditionErrors);
      this.setState({
        preConditionBln: false
      });
      return;
    }

    const resp: any = await postDeviceRegisrationConfirmation(this.state.deviceToken, this.state.easyLink);
    if (resp && 200 === resp.status) {
      console.log('DeviceRegistrationConfirmation :: resp data=', resp);
      const deviceId = resp.data.deviceId;
      if (!_.isEmpty(deviceId))  {
        let _tmpUrl = this.state.originURL.replace(/_/g, '/').replace(/-/g, '+');

        if (window.atob) {
          _tmpUrl = window.atob(_tmpUrl);
        } else {
          _tmpUrl = Base64.atob(_tmpUrl);
        }
        window.location.href = _tmpUrl;

      }
      this.setState({
        responseSuccessBln: true
      });
    } else {
      console.log('DeviceRegistrationConfirmation:: Error=', resp);

      if (!_.isEmpty(this.state.easyLink)) {
        history.push('/easyLinkRegistrationUrlError');
        return;
      }

      let requestId = resp.requestId;
      if (_.isEmpty(requestId)) {
          requestId = !_.isEmpty(resp.data.errorDetail) ?  resp.data.errorDetail.errorId : 'None';
      }
      const message = DeviceRegistrationConfirmationText.ResponseErrorMessage;

      this.setState({
        responseErrorBln: true,
        errorDetail: {
          requestId,
          message
        }
      });
    }
  }

  public validtePreConditions = () => {
    const errors: any = {};

    if (_.isEmpty(this.state.deviceToken)) {
      errors.deviceToken = 'No deviceToken in n request url params from device registrion email';
    }

    if (_.isEmpty(this.state.originURL)) {
      errors.originURL = 'No originURL in n request url params from device registrion email';
    }

    return errors;
  };

  public renderDom() {
    const { DeviceRegistrationConfirmationText } = this.context.localeData;
    return (
      <div >
        {!this.state.preConditionBln &&
          <div className="alert alert-danger">
            <h5>
              {DeviceRegistrationConfirmationText.PreconditionError}
            </h5>
            <h6>
              {_.isEmpty(this.state.deviceToken) && DeviceRegistrationConfirmationText.NoDeviceToken}
            </h6>
            <h6>
              {_.isEmpty(this.state.originURL) && DeviceRegistrationConfirmationText.NoOriginURL}
            </h6>
          </div>
        }

        {this.state.responseErrorBln &&
          <ResponseErrorDetails errorDetail={this.state.errorDetail} />
        }

        {this.state.preConditionBln && !this.state.responseErrorBln && !this.state.responseSuccessBln &&
          <div className="loadable-state" id="react-loadable-loading"> <Loading /></div>
        }
      </div>
    );
  }

  public render() {
    return this.renderDom();
  }

}

export default (DeviceRegistrationConfirmation);
