import axios from 'axios';
import _ from 'lodash';
import './evercookie_crisp';

export const checkEvercookieInBrowser = async (deviceCookieName: string) => {
    let cookieInEvercookie = null;
    try {

        let domain = '';
        if (window.location.host.indexOf('localhost') > -1) {
            domain = 'localhost';
        } else {
            const arr = window.location.host.split('.');
            if (arr.length) {
                domain += '.';
                domain += arr[arr.length - 2];
                domain += '.';
                domain += arr[arr.length - 1];
            }
        }
        const ec = new (window as any).evercookie(domain);
        const ecResp = await ec.get(deviceCookieName);

        if (!_.isEmpty(ecResp) && !_.isEmpty(ecResp.val)) {
            cookieInEvercookie = ecResp.val;
        }
        console.log('checkEvercookieInBrowser success=', cookieInEvercookie);
    } catch (error) {
        console.error('Error:: checkEvercookieInBrowser=', error);
    }
    return cookieInEvercookie;
};

export const resetDeviceIdBasedonEvercookieValue = async (cookieValueInEvercookie: string) => {
    let resetDeviceId = null;
    try {
        const response = await axios({
            method: 'post',
            url: '/rendition/registrationws/eppublic/V1/evercookie.json?ts=' + new Date().getTime(),
            headers: {
                'callerId': 'REGISTRATION_PAGE',
                'accept': 'application/json'
            },
            data: {
                deviceId: cookieValueInEvercookie,
            }
        });

        resetDeviceId = response.data;

        console.log('resetDeviceIdBasedonEvercookieValue success=', resetDeviceId);
    } catch (error: Error | any) {
        if (error.response) {
            console.error('Error:: resetDeviceIdBasedonEvercookieValue response=', error.response);
        } else if (error.request) {
            console.error('Error:: resetDeviceIdBasedonEvercookieValue request=', error.request);
        } else {
            console.error('Error:: resetDeviceIdBasedonEvercookieValue message=', error.message);
        }
        console.error('Error:: resetDeviceIdBasedonEvercookieValue=', error);
    }
    return resetDeviceId;
};

export const postDeviceRegistration = async (payloadData: any) => {

    let responeData: any = {};
    try {
        const response = await axios({
            method: 'post',
            url: '/rendition/registrationws/eppublic/V1/deviceRegistration.json?ts=' + new Date().getTime(),
            headers: {
                'callerId': 'REGISTRATION_PAGE',
                'accept': 'application/json'
            },
            data: {
                originURL: payloadData.originURL,
                emailAddress: payloadData.emailAddress,
                locale: payloadData.locale
            }
        });

        responeData = {status: response.status, data: response.data};

        console.log('postDeviceRegistration success=', responeData);
    } catch (error: any) {
        if (error.response) {
            const requestId = error.response.headers['x-citiportal-requestid'];
            responeData = {status: error.response.status, data: error.response.data, requestId};
            console.error('Error:: postDeviceRegistration response=', error.response);
        } else if (error.request) {
            console.error('Error:: postDeviceRegistration request=', error.request);
        } else {
            console.error('Error:: postDeviceRegistration message=', error.message);
        }
        console.error('Error:: postDeviceRegistratione=', error);
    }
    return responeData;

};

export const postDeviceRegisrationConfirmation = async (deviceToken: string, easyLink: string) => {

    let responeData: any = {};

    let tempUrl = '/rendition/registrationws/eppublic/V1/deviceRegistrationConfirmation.json?ts=';
    if (!_.isEmpty(easyLink)) {
        tempUrl = `/rendition/registrationws/eppublic/V1/deviceRegistrationConfirmation.json?easyLink=${easyLink}&ts=`;
    }

    try {
        const response = await axios({
            method: 'post',
            url: tempUrl + new Date().getTime(),
            headers: {
                'callerId': 'CONFIRMATION_PAGE',
                'accept': 'application/json'
            },
            data: {
                deviceToken
            }
        });

        responeData = {status: response.status, data: response.data};

        console.log('postDeviceRegisrationConfirmation success=', responeData);

    } catch (error: any) {
        if (error.response) {
            const requestId = error.response.headers['x-citiportal-requestid'];
            responeData = {status: error.response.status, data: error.response.data, requestId};
            console.error('ERROR:: postDeviceRegisrationConfirmation response=', responeData);
        } else if (error.request) {
            console.error('ERROR:: postDeviceRegisrationConfirmation request=', error.request);
        } else {
            console.error('ERROR:: postDeviceRegisrationConfirmation message=', error.message);
        }
        console.error('ERROR:: postDeviceRegisrationConfirmation=', error);
    }
    return responeData;
};

export const getNewClientRegistrationDetail = async (newClientRegistrationId: string) => {

    let newClientRegistrationDetail: any = null;

    try {
        const response = await axios({
            method: 'get',
            url: '/rendition/registrationws/eppublic/V1/newClientRegistration.json',
            headers: {
                'callerId': 'NEW_CLIENT_REGISTRATION_PAGE',
                'accept': 'application/json'
            },
            params: {
                newClientRegistrationId,
                ts: new Date().getTime()
            }
        });

        const respData = await response.data;
        if (!_.isEmpty(respData.newClientRegistrationDetail)){
            newClientRegistrationDetail = respData.newClientRegistrationDetail;
        }
        console.log('getNewClientRegistrationDetail=', respData);
    } catch (error) {
        console.error('ERROR getNewClientRegistrationDetail::', error);
    }
    return newClientRegistrationDetail;
};

export const postNewClientRegistration = async (formData: any) => {

    let respData: any = null;

    try {
        const response = await axios({
            method: 'post',
            url: '/rendition/registrationws/eppublic/V1/newClientRegistration.json?ts=' + new Date().getTime(),
            headers: {
                'callerId': 'NEW_CLIENT_REGISTRATION_PAGE',
                'accept': 'application/json'
            },
            data: formData
        });

        respData = await response.data;

        console.log('postNewClientRegistration=', respData);
    } catch (error) {
        console.error('ERROR:: postNewClientRegistration', error);
    }
    return respData;
};

export const getMSCaptcha = async () => {
    let respData: any = null;
    try {
        const response = await axios({
            method: 'get',
            url: '/foundation/captchams/eppublic/V1/captcha.json?ts=' + new Date().getTime(),
            headers: {
                'callerId': 'REGISTRATION_PAGE',
                'Accept': 'application/json'
            }
        });

        respData = await response.data;
        console.log('getCaptcha=', respData);
    } catch (error) {
        console.error('ERROR::  getCaptcha=', error);
    }
    return respData;
};

export const verifyMSCaptcha = async (key: string, value: string) => {
    let respData: any = {};
    try {
        const response = await axios({
            method: 'post',
            url: '/foundation/captchams/eppublic/V1/captcha.json?ts=' + new Date().getTime(),
            headers: {
                'callerId': 'REGISTRATION_PAGE',
                'Accept': 'application/json'
            },
            data: {
                key,
                value
            }
        });
        respData = await response.data;
        respData.status  = 'success';
        console.log('verifyMSCaptcha=', respData);
    } catch (error) {
        respData.status  = 'error';
        console.error('ERROR:: verifyMSCaptcha=', error);
    }
    return respData;
};

const SERVICES = {
    postDeviceRegistration,
    postDeviceRegisrationConfirmation,
    getNewClientRegistrationDetail,
    postNewClientRegistration,
    getMSCaptcha,
    verifyMSCaptcha
};

export default SERVICES;
