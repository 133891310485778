import React from 'react';
import RegistrationContext from '../store/registrationContext';
import LanguageHeader from './languageHeader';

export class NewClientRegistrationSuccess extends React.Component {

  public static contextType = RegistrationContext;

  public renderDom() {
    const { NewClientRegistrationSuccessText } = this.context.localeData;

    return (
      <div id="newClientRegistationSuccess">
        <LanguageHeader headerTitle={''} />
        <div className="message-text">
          {NewClientRegistrationSuccessText.content1}
        </div>

      </div>
    );
  }

  public render() {
    return this.renderDom();
  }

}

export default (NewClientRegistrationSuccess);
