import React from 'react';
import _ from 'lodash';
import history from '../history';

import RegistrationContext from '../store/registrationContext';
import LanguageHeader from './languageHeader';
import { getUrlParameter } from '../utils/registrationUtils';

interface INewClientRegistrationErrorStatus {
  locale: string;
  originURL: string;
}

export class NewClientRegistrationError extends React.PureComponent<any, INewClientRegistrationErrorStatus> {

  public static contextType = RegistrationContext;

  constructor(props: any) {
    super(props);

    let localeParam = getUrlParameter('/newClientRegistrationError?', 'locale');
    const originURL = getUrlParameter('/newClientRegistrationError?', 'originURL');

    if (_.isEmpty(originURL)) {
      console.error("No originURL for this new client registratoin:");
    }

    if (_.isEmpty(localeParam)) {
      localeParam = 'EN_US';
    }

    this.state = {
      locale: localeParam,
      originURL
    };

  }

  public redirectDeviceRegistrationPage = (event: any) => {
    event.preventDefault();
    history.push({
      pathname: '/',
    });
  }

  public renderDom() {
    const { NewClientRegistrationErrorText } = this.context.localeData;

    return (
      <div id="newClientRegistrationError">

        <LanguageHeader headerTitle={''} />

        <div className="alert alert-warning">
          <h5>
            {NewClientRegistrationErrorText.ErrorMessage1}
            <a href="#" className='text-danger text-decoration-undline' onClick={(e) => this.redirectDeviceRegistrationPage(e)}>
              {NewClientRegistrationErrorText.ErrorMessage2}
            </a>
          </h5>
        </div>
      </div>
    );
  }

  public render() {
    return this.renderDom();
  }
}

export default (NewClientRegistrationError);
