import React from 'react';
import RegistrationContext from '../store/registrationContext';

interface IResponseErrorDetailsProps {
  errorDetail: any;
}

export class ResponseErrorDetails extends React.Component<IResponseErrorDetailsProps, any> {

  public static contextType = RegistrationContext;

  constructor(props: IResponseErrorDetailsProps) {
    super(props);
  }

  public renderDom() {
    const { requestId, message } = this.props.errorDetail;

    return (
      <div id="#responseError" className="alert alert-warning">
        <div className="error-type">
          <strong>{message}</strong>
        </div>
        <div className="request_id">RequestId: {requestId}</div>
      </div>
    );
  }

  public render() {
    return this.renderDom();
  }
}

export default ResponseErrorDetails;
