import React from 'react';

// to implement: https://uat.citivelocity.com/rendition/eppublic/akpublic/framebusting/FrameBustError.html
// test
export const Framebust = () => {
    return (<div id="frame_is_blocked">
                <div className="alert alert-danger x-rsch-alert">
                    <div>
                        <span>Frame is blocked</span>
                    </div>
                </div>
            </div>);
};

export default Framebust;
