import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Header from './components/header';
import RegistationFooter from './components/registrationFooter';
import DeviceRegistration from './components/deviceRegistraton';
import DeviceRegistrationSuccess from './components/deviceRegistrationSuccess';
import DeviceRegistrationConfirmation from './components/deviceRegistrationConfirmation';
import NewClientRegistration from './components/newClientRegistration';
import NewClientRegistrationSuccess from './components/newClientRegistrationSuccess';
import NewClientRegistrationError from './components/newClientRegistrationError';
import EasyLinkRegistrationUrlError from './components/easyLinkRegistrationUrlError';
import { LanguageStore } from './store/registrationContext';

export class RegistrationContainer extends React.Component {

    public render() {
        return (
            <div id="outerContainer">
                <HashRouter>
                    <LanguageStore >
                        <div id="outerContainerWraper">
                            <Header />
                            <div id="mainContainer">
                                <Switch>
                                    <Route path="/" exact component={DeviceRegistration} />
                                    <Route path="/deviceRegistration" exact component={DeviceRegistration} />
                                    <Route path="/deviceRegistrationSuccess" exact component={DeviceRegistrationSuccess} />
                                    <Route path="/deviceRegistrationConfirmation" exact component={DeviceRegistrationConfirmation} />
                                    <Route path="/easyLinkRegistrationUrlError" exact component={EasyLinkRegistrationUrlError} />
                                    <Route path="/newClientRegistration" exact component={NewClientRegistration} />
                                    <Route path="/newClientRegistrationSuccess" exact component={NewClientRegistrationSuccess} />
                                    <Route path="/newClientRegistrationError" exact component={NewClientRegistrationError} />
                                </Switch>
                            </div>
                        </div>
                        <RegistationFooter />
                    </LanguageStore>
                </HashRouter>
            </div>
        );
    }
}

export default (RegistrationContainer);
