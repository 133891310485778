const countryOptionEN = [
    'Afghanistan',
    'Albania', 'Algeria', 'American Samoa', 'Andorra', 'Angola',
    'Anguilla', 'Antarctica', 'Antigua and Barbuda', 'Argentina', 'Armenia',
    'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas',
    'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium',
    'Belize', 'Benin', 'Bermuda', 'Bhutan', 'Bolivia',
    'Bosnia and Herzegovina', 'Botswana', 'Bouvet Island', 'Brazil', 'British Indian Ocean Territory',
    'British Virgin Islands', 'Brunei Darussalam', 'Bulgaria', 'Burkina Faso', 'Burundi',
    'Cambodia', 'Cameroon', 'Canada', 'Cape Verde', 'Cayman Islands',
    'Central African Republic', 'Chad', 'Channel Islands', 'Chile', 'China',
    'Christmas Island', 'Cocos (Keeling) Islands', 'Colombia', 'Comoros', 'Congo',
    'Congo, The Democratic Republic', 'Cook Islands', 'Costa Rica', 'Cote D\'ivoire', 'Croatia',
    'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti',
    'Dominica', 'Dominican Republic', 'East Timor', 'Ecuador', 'Egypt',
    'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Ethiopia',
    'Falkland Islands (Malvinas)', 'Faroe Islands', 'Fiji', 'Finland', 'France',
    'French Guiana', 'French Polynesia', 'French Southern Territories', 'Gabon', 'Gambia',
    'Georgia', 'Germany', 'Ghana', 'Gibraltar', 'Greece',
    'Greenland', 'Grenada', 'Guadeloupe', 'Guam', 'Guatemala',
    'Guinea', 'Guinea-bissau', 'Guyana', 'Haiti', 'Heard and Mc Donald Islands',
    'Holland', 'Holy See (Vatican City State)', 'Honduras', 'Hong Kong', 'Hungary',
    'Iceland', 'India', 'Indonesia', 'Iran (Islamic Republic of)', 'Iraq',
    'Ireland', 'Isle Of Man', 'Israel', 'Italy', 'Jamaica',
    'Japan', 'Jersey', 'Jordan', 'Kazakhstan', 'Kenya',
    'Kiribati', 'Korea, Democratic People\'s Rep', 'Kuwait', 'Kyrgyzstan', 'Lao People\'s Democratic Republ',
    'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libyan Arab Jamahiriya',
    'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macau', 'Macedonia, The Former Yugoslav',
    'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali',
    'Malta', 'Marshall Islands', 'Martinique', 'Mauritania', 'Mauritius',
    'Mayotte', 'Mexico', 'Micronesia, Federated States o', 'Moldova, Republic of', 'Monaco',
    'Mongolia', 'Montenegro', 'Montserrat', 'Morocco', 'Mozambique',
    'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands',
    'Netherlands Antilles', 'New Caledonia', 'New Zealand', 'Nicaragua', 'Niger',
    'Nigeria', 'Niue', 'No Country', 'Norfolk Island', 'Northern Mariana Islands',
    'Norway', 'Oman', 'Pakistan', 'Palau', 'Panama',
    'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Pitcairn',
    'Poland', 'Portugal', 'Puerto Rico', 'Qatar', 'Reunion',
    'Romania', 'Russian Federation', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia',
    'Saint Vincent and the Grenadin', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia',
    'Scandinavia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone',
    'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia',
    'South Africa', 'South Georgia and the South Sa', 'South Korea', 'Spain', 'Sri Lanka',
    'St. Helena', 'St. Pierre and Miquelon', 'Sudan', 'Suriname', 'Svalbard and Jan Mayen Islands',
    'Swaziland', 'Sweden', 'Switzerland', 'Syrian Arab Republic', 'Taiwan',
    'Tajikistan', 'Tanzania', 'Thailand', 'Timor- Leste', 'Togo',
    'Tokelau', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey',
    'Turkmenistan', 'Turks and Caicos Islands', 'Tuvalu', 'US Virgin Islands', 'Uganda',
    'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay',
    'Uzbekistan', 'Vanuatu', 'Venezuela', 'Viet Nam', 'Wallis and Futuna Islands',
    'Western Sahara', 'Yemen', 'Zambia', 'Zimbabwe',
];

const countryOptionJP = [
    'アフガニスタン',
    'アルバニア', 'アルジェリア', '米サモア', 'アンドラ', 'アンゴラ',
    'アングィラ', '南極', 'アンチグアバーブーダ', 'アルゼンチン', 'アルメニア',
    'アルバ', 'オーストラリア', 'オーストリア', 'アゼルバイジャン', 'バハマ',
    'バーレーン', 'バングラデシュ', 'バルバドス', 'ベラルーシ', 'ベルギー',
    'ベリーズ', 'ベナン', 'バミューダ', 'ブータン', 'ボリビア',
    'ボスニア・ヘルツェゴビナ', 'ボツワナ', 'ブーヴェ島', 'ブラジル', '英領インド洋地域',
    '英領ヴァージン諸島', 'ブルネイ・ダルサラーム', 'ブルガリア', 'ブルキナファソ', 'ブルンジ',
    'カンボジア', 'カメルーン', 'カナダ', 'カーポベルデ', 'ケイマン諸島',
    '中央アフリカ共和国', 'チャド', 'チャネル諸島', 'チリ', '中国',
    'クリスマス島', 'ココス諸島', 'コロンビア', 'コモロ', 'コンゴ',
    'コンゴ民主共和国', 'クック諸島', 'コスタリカ', 'コートジボアール', 'クロアチア',
    'キューバ', 'キプロス', 'チェコ共和国', 'デンマーク', 'ジブチ',
    'ドミニカ', 'ドミニカ共和国', '東チモール', 'エクアドル', 'エジプト',
    'エルサルバドル', '赤道ギニア', 'エリトリア', 'エストニア', 'エチオピア',
    'フォークランド諸島(マルビナス諸島)', 'フェロー諸島', 'フィジー', 'フィンランド', 'フランス',
    '仏領ギアナ', '仏領ポリネシア', '仏領南方・南極地域', 'ガボン', 'ガンビア',
    'グルジア', 'ドイツ', 'ガーナ', 'ジブラルタル', 'ギリシャ',
    'グリーンランド', 'グレナダ', 'グアドループ島', 'グアム', 'グアテマラ',
    'ギニア', 'ギニアビサウ', 'ガイアナ', 'ハイチ', 'ハード島・マクドナルド諸島',
    'オランダ', '教皇庁(バチカン市国)', 'ホンデュラス', '香港', 'ハンガリー',
    'アイスランド', 'インド', 'インドネシア', 'イラン・イスラム共和国', 'イラク',
    'アイルランド', 'マン島', 'イスラエル', 'イタリア', 'ジャマイカ',
    '日本', 'ジャージー', 'ヨルダン', 'カザフスタン', 'ケニア',
    'キリバス', '大韓民国', 'クウェート', 'キルギルスタン', 'ラオス人民民主共和国',
    'ラトビア', 'レバノン', 'レソト', 'リベリア', 'リビア',
    'リヒテンシュタイン', 'リトアニア', 'ルクセンブルグ', 'マカオ', 'マケドニア旧ユーゴスラビア共和国',
    'マダガスカル', 'マラウィ', 'マレーシア', 'モルジブ', 'マリ',
    'マルタ', 'マーシャル諸島', 'マルティニーク諸島', 'モーリタニア', 'モーリシャス',
    'マヨット島', 'メキシコ', 'ミクロネシア連邦', 'モルドバ共和国', 'モナコ',
    'モンゴル', 'モンテネグロ', 'モントセラト島', 'モロッコ', 'モザンビーク',
    'ミャンマー', 'ナミビア', 'ナウル', 'ネパール', 'オランダ',
    'オランダ領アンティル', 'ニューカレドニア', 'ニュージーランド', 'ニカラグア', 'ニジェール',
    'ナイジェリア', 'ニウエ', '該当国なし', 'ノーフォーク島', '北マリアナ諸島',
    'ノルウェー', 'オマーン', 'パキスタン', 'パラオ', 'パナマ',
    'パプアニューギニア', 'パラグアイ', 'ペルー', 'フィリピン', 'ピトケアン島',
    'ポーランド', 'ポルトガル', 'プエルトリコ', 'カタール', 'レユニオン',
    'ルーマニア', 'ロシア連邦', 'ルワンダ', 'セントクリストファー・ネイビス', 'セントルシア',
    'セントビンセント・グレナディーン', 'サモア', 'サンマリノ', 'サントメ・プリンシペ', 'サウジアラビア',
    'スカンジナビア', 'セネガル', 'セルビア', 'セーシェル', 'シエラレオネ',
    'シンガポール', 'スロバキア', 'スロベニア', 'ソロモン諸島', 'ソマリア',
    '南アフリカ', 'サウスジョージア・サウスサンドウィッチ諸島', '韓国', 'スペイン', 'スリランカ',
    'セントヘレナ', 'サンピエール島・ミクロン島', 'スーダン', 'スリナム', 'スヴァールバル諸島ヤンマイエン島',
    'スワジランド', 'スウェーデン', 'スイス', 'シリア', '台湾',
    'タジキスタン', 'タンザニア', 'タイ', '東ティモール', 'トーゴ',
    'トケラウ諸島', 'トンガ', 'トリニダード・トバゴ', 'チュニジア', 'トルコ',
    'トルクメニスタン', 'タークス・カイコス諸島', 'ツバル', '米領ヴァージン諸島', 'ウガンダ',
    'ウクライナ', 'アラブ首長国連邦', '英国', '米国', 'ウルグアイ',
    'ウズベキスタン', 'バヌアツ', 'ベネズエラ', 'ベトナム', 'ウォリス・フトゥーナ諸島',
    '西サハラ', 'イエメン', 'ザンビア', 'ジンバブエ'
];

const positionOptionEN = [
    {value: 'Analyst', label: 'Analyst'},
    {value: 'AssetAllocator', label: 'Asset Allocator'},
    {value: 'Assistant', label: 'Assistant'},
    {value: 'CEO', label: 'CEO'},
    {value: 'CFO', label: 'CFO'},
    {value: 'CIO', label: 'CIO'},
    {value: 'COO', label: 'COO'},
    {value: 'Director', label: 'Director'},
    {value: 'Chairman', label: 'Chairman'},
    {value: 'ChiefInformationOfficer', label: 'Chief Information Officer'},
    {value: 'DirectorofOperations', label: 'Director of Operations'},
    {value: 'Dealer_Trader', label: 'Dealer/Trader'},
    {value: 'Economist', label: 'Economist'},
    {value: 'AssistantDirector', label: 'Assistant Director'},
    {value: 'HeadofResearch', label: 'Head of Research'},
    {value: 'HeadofSales', label: 'Head of Sales'},
    {value: 'HeadofTrading', label: 'Head of Trading'},
    {value: 'InvestorRelations', label: 'Investor Relations'},
    {value: 'MiddleOffice', label: 'Middle Office'},
    {value: 'Operations', label: 'Operations'},
    {value: 'PM', label: 'PM'},
    {value: 'ResearchAssociate', label: 'Research Associate'},
    {value: 'Quant', label: 'Quant'},
    {value: 'Secretary', label: 'Secretary'},
    {value: 'Strategist', label: 'Strategist'},
    {value: 'OfficeAdministrator', label: 'Office Administrator'},
    {value: 'InvestmentDirector', label: 'Investment Director'},
    {value: 'UnitHeadInvestmentService', label: 'Unit Head Investment Service'},
    {value: 'ComplianceOfficer', label: 'Compliance Officer'},
    {value: 'Legal', label: 'Legal'}
];

const positionOptionJP = [
    {value: 'Analyst', label: 'アナリスト'},
    {value: 'AssetAllocator', label: '運用担当者'},
    {value: 'Assistant', label: 'アシスタント'},
    {value: 'CEO', label: 'CEO'},
    {value: 'CFO', label: 'CFO'},
    {value: 'CIO', label: 'CIO'},
    {value: 'COO', label: 'COO'},
    {value: 'Director', label: 'ディレクター'},
    {value: 'Chairman', label: '会長'},
    {value: 'ChiefInformationOfficer', label: 'CIO'},
    {value: 'DirectorofOperations', label: '事業本部長'},
    {value: 'Dealer_Trader', label: 'ディーラー/トレーダー'},
    {value: 'Economist', label: 'エコノミスト'},
    {value: 'AssistantDirector', label: 'アシスタント・ディレクター'},
    {value: 'HeadofResearch', label: '調査部長'},
    {value: 'HeadofSales', label: '営業部長'},
    {value: 'HeadofTrading', label: 'トレーディング部長'},
    {value: 'InvestorRelations', label: 'IR'},
    {value: 'MiddleOffice', label: 'ミドルオフィス'},
    {value: 'Operations', label: 'ミドルオフィス'},
    {value: 'PM', label: 'プロジェクトマネジャー'},
    {value: 'ResearchAssociate', label: 'リサーチアソシエート'},
    {value: 'Quant', label: 'クオンツ'},
    {value: 'Secretary', label: 'セクレタリー'},
    {value: 'Strategist', label: 'ストラテジスト'},
    {value: 'OfficeAdministrator', label: 'オフィスアドミニストレーター'},
    {value: 'InvestmentDirector', label: 'インベストメントバンキング・ディレクター'},
    {value: 'UnitHeadInvestmentService', label: 'インベストメントサービス・ユニットヘッド'},
    {value: 'ComplianceOfficer', label: 'コンプライアンスオフィサー'},
    {value: 'Legal', label: '法務部'}
];

const clientTypeOptionEN = [
    'Institutional', 'Corporate', 'Education', 'Government/Regulatory', 'Media', 'Retail',
];

const clientTypeOptionJP = [
    '法人', 'コーポレート', '教育', '政府/規制', 'メディア', 'リテール',
];

const salutationOption = [
    'Mr.', 'Mrs.', 'Ms.', 'Miss', 'Sir', 'Dr.', 'The Hon',
];

export const JPTexts = {
        LanguageHeaderText: {
            'english': 'English',
            'japanese': '日本語'
        },
        ResponseErrorDetailsText: {
            'errorHeader': 'エラーメッセージ',
            'errorType': 'エラータイプ',
            'errorReason': 'エラーの理由',
            'errorDescription': 'エラーの説明',
        },
        FooterText: {
            'CONTACT_SUPPORT': 'コンタクト・サポート',
            'PrivacyStatement': '\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30FB\u30B9\u30C6\u30FC\u30C8\u30E1\u30F3\u30C8',
            'TermsOfUse': '\u5229\u7528\u898F\u7D04',
            'Copyright_1': 'Copyright ',
            'Copyright_2': ' Citi and/or its affiliates.  All Rights Reserved.',
        },
        DeviceRegistrationText: {
            'title': '\u65B0\u3057\u3044\u30C7\u30B8\u30BF\u30EB\u30FB\u30EA\u30B5\u30FC\u30C1\u30FB\u30D7\u30ED\u30C0\u30AF\u30C8\u306B\u3088\u3046\u3053\u305D',
            'content1': '\u30b3\u30f3\u30c6\u30f3\u30c4\u306b\u30a2\u30af\u30bb\u30b9\u3059\u308b\u306b\u306f\u3001\u4e00\u5ea6\u306e\u767b\u9332\u624b\u7d9a\u304d\u304c\u5fc5\u8981\u3067\u3059\u3002\u0020\u3042\u306a\u305f\u306e\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u3092\u5165\u529b\u3057\u3066\u3001\u300c',
            'content2': '\u767b\u9332',
            'content3': ' \u300d\u3092\u30af\u30ea\u30c3\u30af\u3057\u3066\u304f\u3060\u3055\u3044\u3002\u0020\u767b\u9332\u3092\u5b8c\u4e86\u3059\u308b\u305f\u3081\u306b\u6570\u5206\u4ee5\u5185\u306b\u3042\u306a\u305f\u306e\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u306b\u78ba\u8a8d\u30e1\u30fc\u30eb\u304c\u9001\u4fe1\u3055\u308c\u307e\u3059\u3002',
            'guideTextStart': '\u0043\u0069\u0074\u0069\u0020\u0052\u0065\u0073\u0065\u0061\u0072\u0063\u0068\u306b\u30a2\u30af\u30bb\u30b9\u3059\u308b\u305f\u3073\u306b\u30c7\u30d0\u30a4\u30b9\u3092\u767b\u9332\u3059\u308b\u5fc5\u8981\u304c\u3042\u308a\u307e\u3059\u304b\uff1f',
            'guideTextStart1': '\u304a\u4f7f\u3044\u306e\u30d6\u30e9\u30a6\u30b6\u306e\u8a2d\u5b9a\u3092\u5909\u66f4\u3059\u308b\u306b\u306f\u3001',
            'guideLink': '\u30c7\u30d0\u30a4\u30b9\u767b\u9332\u30ac\u30a4\u30c9',
            'guideTextEnd': '\u3092\u3054\u89a7\u304f\u3060\u3055\u3044\u3002',
            'notciti1': '\u30B7\u30C6\u30A3\u306E\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u3067\u306A\u3044\u5834\u5408\u306F' ,
            'notciti2': '\u3053\u3061\u3089',
            'notciti3': '\u3092\u30AF\u30EA\u30C3\u30AF\u3055\u3089\u306a\u308b\u30b5\u30dd\u30fc\u30c8\u304c\u5fc5\u8981\u306a\u5834\u5408\u306f\u3001\u5f53\u793e\u306e\u30b5\u30dd\u30fc\u30c8\u30c1\u30fc\u30e0 ',
            'notciti4': ')\u306b\u3054\u9023\u7d61\u304f\u3060\u3055\u3044\u3002',
            'inputLabel': '\u4F1A\u793E\u306EEmail\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B',
            'submit':  '\u767b\u9332',
            'watchVideoMsg': '\u8A73\u7D30\u306F\u30D3\u30C7\u30AA\u3092\u3054\u89A7\u304F\u3060\u3055\u3044',
            'PrivacyStatement':'\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30FB\u30B9\u30C6\u30FC\u30C8\u30E1\u30F3\u30C8',
            'TermsOfUse': '\u5229\u7528\u898F\u7D04',
            'NoOriginURL': "このデバイスを登録できません。リクエストURLにoriginURLパラメータがありません。",
            'EmailAddressInvalid': '会社のメールアドレスを入力してください。',
            'ValiateEmailAddressRequired': '有効なメールアドレスを入力してください。',
            'ResponseErrorMessage': 'このデバイスの登録中に問題が発生しました。 もう一度試すか、シティの担当者にお問い合わせください。',
        },
        DeviceRegistrationSuccessText: {
            'title': '\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\u3082\u3046\u5C11\u3057\u3067\u30B7\u30C6\u30A3\u30FB\u30EA\u30B5\u30FC\u30C1\u306B\u30A2\u30AF\u30BB\u30B9\u3067\u304D\u307E\u3059\u3002',
            'content1': '\u307e\u3082\u306a\u304f\u767b\u9332\u3092\u5b8c\u4e86\u3059\u308b\u305f\u3081\u306e\u78ba\u8a8d\u30e1\u30fc\u30eb\u304c\u5c4a\u304d\u307e\u3059\uff1a',
            'content2': '\u53d7\u4fe1\u30c8\u30ec\u30a4\u306b\u78ba\u8a8d\u30e1\u30fc\u30eb\u304c\u8868\u793a\u3055\u308c\u306a\u3044\u5834\u5408\u306f\u3001\u30b9\u30d1\u30e0\u307e\u305f\u306f\u8ff7\u60d1\u30e1\u30fc\u30eb\u30d5\u30a9\u30eb\u30c0\u3092\u78ba\u8a8d\u3057\u3066\u304f\u3060\u3055\u3044\u3002',
            'content21': '\u3055\u3089\u306a\u308b\u652f\u63f4\u304c\u5fc5\u8981\u306a\u5834\u5408\u306f\u3001\u0043\u0069\u0074\u0069\u0020\u0053\u0061\u006c\u0065\u0073\u62c5\u5f53\u8005\u307e\u305f\u306f\u5f53\u793e\u306e\u30b5\u30dd\u30fc\u30c8\u30c1\u30fc\u30e0(',
            'content3': ' ',
            'content4': ')',
            'content5': ' \u306b\u304a\u554f\u3044\u5408\u308f\u305b\u304f\u3060\u3055\u3044\u3002',
            'content51': 'rschaccess@citi.com',
            'content6': ' 受信したメールの指示に従ってください。',
            'imageLink': 'eppublic/akpublic/images/token-email.png'
        },
        NewClientRegistrationSuccessText: {
            'content1': '\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\u3002\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u767B\u9332\u7533\u8FBC\u66F8\u3092\u53D7\u3051\u4ED8\u3051\u307E\u3057\u305F\u3002'
        },
        NewClientRegistrationErrorText: {
            'ErrorMessage1': '登録リンクが期限切れか、以前に使用されていました。 もう一度お試しください。クリックしてください ',
            'ErrorMessage2': 'ここに。 ',
        },
        DeviceRegistrationConfirmationText: {
            'PreconditionError': 'このページにアクセスできません：',
            'NoDeviceToken': 'デバイス登録メールのリクエストURLパラメータにデバイストークンがありません',
            'NoOriginURL': 'デバイス登録メールからのn個のリクエストURLパラメータにoriginalはありません',
            'ResponseErrorMessage': 'このデバイスの登録の確認中に問題が発生しました。 もう一度試すか、シティの担当者にお問い合わせください。',
        },
        NewClientRegistrationText: {
            NewClientRegistration: {
                'New_Client_Registration_Form_NO_EMAIL': 'メールリンクはすでに使用されているか、期限切れです。 もう一度お試しください。',
                'New_Client_Registration_Form_NO_EMAIL_HERE': ' ここに ',
                'NEW_CLIENT_REGISTRATION_FORM': 'クライアント登録申込書',
                'Required': '必須',
                'PERSONAL_INFORMATION': '個人情報',
                'Position': '役職',
                'Client_Type': 'クライアントの種類',
                'Salutation': '肩書',
                'First_Name': '名',
                'Middle_Initial': '',
                'Last_Name': '姓',
                'Professional_Title': '役職名',
                'Company_Emai_Address': '会社のemailアドレス',
                'Re_enter_Company_Email_Address': '会社のemailアドレスを再入力',
                'Telephone_Number': '電話番号',
                'COMPANY_INFORMATION': '会社の情報',
                'Company_Name': '社名',
                'Mailing_Address': 'メーリングアドレス',
                'Mailing_Address_line_2': 'メーリングアドレス2行目',
                'City': '市',
                'State_Province': '都道府県',
                'Zip_Postal_Code': '郵便番号',
                'Country': '国名',
                'CITI_CONTACT_INFORMATION': 'シティ・コンタクト情報',
                'Citi_Contact_Name': 'シティ・コンタクト情報',
                'CAPTCHA_VALIDATE': 'キャプチャ',
                'Please_input_captcha': 'キャプチャを入力してください (大文字小文字を区別しません)',
                'Submit_message': 'クライアント登録申込書にご記入のうえ送信してください。シティ担当者による承認の後、ご指定のemailアドレス宛にユーザーネームとパスワードをお送りします。',
                'Submit_button': 'サブミット',
                'Reset_button': 'リセット',
                'email_subject': 'クライアント登録申込書',
                'CONTACT_SUPPORT': 'コンタクト・サポート',
                'ncr_language': 'ja',
            },
            PositionOptions: positionOptionJP,
            PositionOptionValue: positionOptionEN,
            ClientTypeOptions: clientTypeOptionJP,
            ClientTypeOptionValue: clientTypeOptionEN,
            SalutationOptions: salutationOption,
            CountryOption: countryOptionJP,
            CountryOptionValue: countryOptionEN,
            copyright: {
                'Copyright_1': 'Copyright ',
                'Copyright_2': ' Citi and/or its affiliates.  All Rights Reserved.',
            }
        }
    };

export const ENTexts = {
        LanguageHeaderText: {
            'english': 'English',
            'japanese': '日本語'
        },
        FooterText: {
            'CONTACT_SUPPORT': 'Contact Support',
            'PrivacyStatement':'Privacy Statement',
            'TermsOfUse': 'Terms of Use',
            'Copyright_1': 'Copyright ',
            'Copyright_2': ' Citi and/or its affiliates.  All Rights Reserved.',
        },
        ResponseErrorDetailsText: {
            'errorHeader': 'Error Messages',
            'errorType': 'Error Type',
            'errorReason': ' Error Reason',
            'errorDescription': ' Error Description',
        },
        DeviceRegistrationText: {
            'title': 'Welcome to Citi\'s digital research product.',
            'content1': 'A one-time registration process is required to gain access to our content. Please enter your email address and click ',
            'content2': 'Register',
            'content3': '. A confirmation email will be sent to your email address within a few minutes to complete your registration.',
            'guideTextStart': 'Having to register your device every time you access Citi Research?',
            'guideTextStart1': 'Please visit our',
            'guideLink': 'Device Registration Guide',
            'guideTextEnd': 'to amend your browser settings.',
            'notciti1': 'If you are not currently a Citi Client, please click' ,
            'notciti2': 'here',
            'notciti3': 'If you require further assistance please contact our support team at',
            'inputLabel': 'Enter Corporate Email Address',
            'submit':  'Register',
            'watchVideoMsg': 'Watch our video to learn more',
            'PrivacyStatement':'Privacy Statement',
            'TermsOfUse': 'Terms of Use',
            'NoOriginURL': "You can not register this device, no originURL param in request URL.",
            'EmailAddressInvalid': 'Please enter your business email address.',
            'ValiateEmailAddressRequired': 'Please enter a valid email address.',
            'ResponseErrorMessage': 'A problem occurred while registering this device. Please try again or contact your Citi representative.',
        },
        DeviceRegistrationSuccessText: {
            'title': 'Thank you. You are one step away from accessing Citi Research.',
            'content1': 'You will receive a confirmation email shortly to complete the registration:',
            'content2': 'If you do not see the confirmation email in your inbox, please check your Spam or Junk folder.',
            'content21': 'If you require further assistance please contact your Citi Sales representative or our support team at',
            'content3': ' ',
            'content4': '.',
            'content5': ' Open the email from ',
            'content51': 'rschaccess@citi.com',
            'content6': ' Please follow instructions in the email you received.',
            'imageLink': 'eppublic/akpublic/images/token-email.png'
        },
        NewClientRegistrationSuccessText: {
            'content1': 'Thank you. Your New Client Registration Form has been received for review.'
        },
        NewClientRegistrationErrorText: {
            'ErrorMessage1': 'Registration link expired or previously used. Please try again ',
            'ErrorMessage2': 'here. ',
        },
        DeviceRegistrationConfirmationText: {
            'PreconditionError': 'You can not access this page:',
            'NoDeviceToken': 'No deviceToken in request url params from device registrion email',
            'NoOriginURL': 'No originURL in n request url params from device registrion email',
            'ResponseErrorMessage': 'A problem occurred while confirming to register this device. Please try again or contact your Citi representative.',
        },
        NewClientRegistrationText: {
            NewClientRegistration: {
                'New_Client_Registration_Form_NO_EMAIL': 'Email link already used up or expired. Please try again. ',
                'New_Client_Registration_Form_NO_EMAIL_HERE': ' Here ',
                'NEW_CLIENT_REGISTRATION_FORM': 'NEW CLIENT REGISTRATION FORM',
                'Required': 'Required',
                'PERSONAL_INFORMATION': 'PERSONAL INFORMATION',
                'Position': 'Position',
                'Client_Type': 'Client Type',
                'Salutation': 'Salutation',
                'First_Name': 'First Name',
                'Middle_Initial': 'Middle Initial',
                'Last_Name': 'Last Name',
                'Professional_Title': 'Professional Title',
                'Company_Emai_Address': 'Company Email Address',
                'Re_enter_Company_Email_Address': 'Re-enter Company Email Address',
                'Telephone_Number': 'Telephone Number',
                'COMPANY_INFORMATION': 'COMPANY INFORMATION',
                'Company_Name': 'Company Name',
                'Mailing_Address': 'Mailing Address',
                'Mailing_Address_line_2': 'Mailing Address line 2',
                'City': 'City',
                'State_Province': 'State/Province',
                'Zip_Postal_Code': 'Zip/Postal Code',
                'Country': 'Country',
                'CITI_CONTACT_INFORMATION': 'CITI CONTACT INFORMATION',
                'Citi_Contact_Name': 'Citi Contact Name',
                'CAPTCHA_VALIDATE': 'CAPTCHA VALIDATE',
                'Please_input_captcha': 'Please input captcha (case insensitive)',
                'Submit_message': 'Submit your completed New Client Registration form.  Upon approval from your Citi representative a username/password will be sent to the email address you provided.',
                'Submit_button': 'Submit',
                'Reset_button': 'Reset',
                'email_subject': 'New%20Client%20Registration%20Inquiry',
                'CONTACT_SUPPORT': 'Contact Support',
                'ncr_language': 'en',
            },
            PositionOptions: positionOptionEN,
            PositionOptionValue: positionOptionEN,
            ClientTypeOptions: clientTypeOptionEN,
            ClientTypeOptionValue: clientTypeOptionEN,
            SalutationOptions: salutationOption,
            CountryOption: countryOptionEN,
            CountryOptionValue: countryOptionEN,
            copyright: {
                'Copyright_1': 'Copyright ',
                'Copyright_2': ' Citi and/or its affiliates.  All Rights Reserved.',
            }
        }
    };
